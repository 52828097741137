import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { DataProvider } from './Components/DataContext';
import { UserProvider, useUser } from './context/UserContext';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Login from './Components/Login';
import Register from './Components/Register';
import UserProfile from './Components/UserProfile';
import VenueProfile from './Components/VenueProfile';
import SearchResults from './Components/SearchResults';
import BookingCalendar from './Components/BookingCalendar';
import Home from './Components/Home';
import PerformersComponent from './Components/PerformersComponent';
import VendorsComponent from './Components/VendorsComponet';
import './App.css';
import FreelancerProfileForm from './Components/FreeLanceProfile';
import PerformerProfileForm from './Components/PerformerProfile';
import VendorProfileForm from './Components/VendorProfile';
import ClearDatabase from './Components/ClearDatabase';
import EditProfile from './Components/EditProfile';
import AboutUsPage from './Components/AboutUs';
import ViewProfile from './Components/ViewProfile';
import BookingPage from './Components/Booking';
import UserBooking from './Components/UsersBooking';
import Messages from './Components/Messages';
import UserRes from './Components/UserRes';
import VenueBookingDashboard from './Components/ImpBooking';
import ResetPassword from './Components/ResetPassword';
import axios from 'axios';
import ForgotPassword from './Components/ForgotPassword';
import ArtistBookingDashboard from './Components/imp_art_contract';
import ArtistBookingHomePage from './Components/ArtistBookingHomePage';
import ContractRoute from './Components/RouteContract';
import VenueLandingPage from './Components/VenueLandingPage';
import ArtistLandingPage from './Components/ArtistHomePage';
import VenueBookingForm from './Components/ImpBookingForm';

axios.defaults.withCredentials = true;

function App() {
  return (
    <Router>
      <UserProvider>
        <Header />
        <div className="main-content">
          <Routes>
            <Route path="/artist/bookings" element={<ArtistBookingDashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/user/profile" element={<UserProfile />} />
            <Route path="/venue/profile" element={<VenueProfile />} />
            <Route path="/search" element={<SearchResults />} />
            <Route path="/contracts/:id" element={<ContractRoute />} />
            <Route path="/bookings" element={<BookingCalendar />} />
            <Route path="/vendor-profile-form" element={<VendorProfileForm />} />
            <Route path="/performer-profile-form" element={<PerformerProfileForm />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/freelancer-profile-form" element={<FreelancerProfileForm />} />
            <Route path="/" element={<DataProvider><Home /></DataProvider>} />
            <Route path="/VenueLandingPage/:id" element={<DataProvider><VenueLandingPage /></DataProvider>} />
            <Route path="/ArtistLandingPage/:id" element={<DataProvider><ArtistLandingPage /></DataProvider>} />
            <Route path="/performers" element={<DataProvider><PerformersComponent /></DataProvider>} />
            <Route path="/vendors" element={<DataProvider><VendorsComponent /></DataProvider>} />
            <Route path="/admin" element={<ClearDatabase />} />
            <Route path="/edit-profile/:profileId" element={<EditProfile />} />
            <Route path='/aboutus' element={<AboutUsPage />} />
            <Route path="/view_profile/:profile_id" element={<ViewProfile />} />
            <Route path="/book/:profile_id" element={<BookingPage />} />
            <Route path="/Userbooking/:profile_id" element={<UserBooking />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/fordurk/:id" element={<VenueBookingForm />} />
            <Route path='/messages/:profile_id' element={<Messages />} />
            <Route path="/Userreservations/:profile_id" element={<UserRes />} />
            <Route path="/VendorImpBooking/:profile_id" element={<VenueBookingDashboard />}/>
            <Route path="/ArtistImpBooking/:profile_id" element={<ArtistBookingHomePage />}/>
          </Routes>
        </div>
        <Footer />
      </UserProvider>
    </Router>
  );
}


export default App;
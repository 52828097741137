import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Header.css';
import axios from 'axios';
import { useUser } from '../context/UserContext';
import icon from './L&L Logo copy.png';  // Adjust the path according to your directory structure

function Header() {
    const { user, logout } = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    const [profileStatus, setProfileStatus] = useState(null);
    const [isProfileLoading, setIsProfileLoading] = useState(true);
    const [profileError, setProfileError] = useState(null);
    const isOnBookingsPage = () => {
        return location.pathname.includes('/Userbooking') || location.pathname.includes('/Userreservations');
    };

    useEffect(() => {
        const fetchProfileId = async () => {
          try {
            const response = await axios.get(`https://api.live-and-local.com/get-profile/${user.user_id}`);
            setProfileStatus(response.data.status); // Adjust based on your API response structure
            setIsProfileLoading(false);
          } catch (error) {
            console.error('Error fetching profile ID:', error);
            setProfileError(error);
            setIsProfileLoading(false);
          }
        };
    
        if (user && user.user_id) {
          fetchProfileId();
        }
      }, [user]);


      const UnstoppableLink = ({ to, children, onClick }) => (
        <Link 
            to={to}
            onClick={onClick}
            style={{
                position: 'relative',
                zIndex: 99999,
                pointerEvents: 'auto',
                cursor: 'pointer',
                display: 'inline-block',
                margin: '0 1%',
                textDecoration: 'none',
                color: '#EF6191',
                fontSize: '16px',
                fontWeight: '500',
                padding: '5px 10px',
            }}
        >
            {children}
        </Link>
    );

    return (
        <>
            <header>
                <div className="header-content">
                    <img src={icon} alt="Logo" className="header-icon" />
                    <h1>Live & Local</h1>
                </div>
                <nav>
                    {user ? (
                        <>
                            {/* Conditional Home link based on profileStatus */}
                            {profileStatus === 'vendor' ? (
                                <>
                                    <Link to={`/VenueLandingPage/${user.user_id}`}>Home</Link>
                                    <Link to='/VendorImpBooking/:profile_id'>Bookings</Link>
                                </>
                            ) : (
                                <>
                                    <Link to={`/ArtistLandingPage/${user.user_id}`}>Home</Link>
                                    <Link to='/ArtistImpBooking/:profile_id'>Bookings</Link>
                                </>
                            )}
                            <Link to="/search">Search</Link>
                            <Link to='/messages/:profile_id'>Messages</Link>
                            <Link to='/user/profile'>Profile</Link>
                            <Link to='/' onClick={() => logout()}>Log Out</Link>
                        </>
                    ) : (
                        <>
                            <UnstoppableLink to="/">Home</UnstoppableLink>
                            <UnstoppableLink to="/search">Search</UnstoppableLink>
                            <UnstoppableLink to="/login">Login</UnstoppableLink>
                            <UnstoppableLink to="/register">Register</UnstoppableLink>
                        </>
                    )}
                </nav>
            </header>
            {isOnBookingsPage() ? (
                <div className="sub-header">
                    <nav>
                        <Link to='/Userbooking/:profile_id'>My Bookings</Link>
                        <Link to='/Userreservations/:profile_id'>My Reservations</Link>
                    </nav>
                </div>
            ) : (
                <div className="sub-header">
                    <nav>
                        <Link to='/performers'>Performers</Link>
                        <Link to='/vendors'>Vendors</Link>
                    </nav>
                </div>
            )}
            <p></p>
        </>
    );
}

export default Header;
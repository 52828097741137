import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Geosuggest from './Geosuggest'; 
import './Instrution.css';
import './Geosuggest.css';
import { Box, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

function PerformerProfileForm() {
    const [performerData, setPerformerData] = useState({
        name: '',
        genre: '',
        image: '',
        bio: '',
        experienceLevel: '',
        preferredVenues: [],
        performanceType: '',
        preferredContactMethod: '',
        phoneNumber: ''
    });
    const [state, setState] = useState('MA');
    const [city, setCity] = useState('');

    const stateAbbreviations = [
        "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",
        "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
        "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
        "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
        "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
    ];

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const instructions = {
        name: "Provide the artist or stage name you use for performances. This is what venues and fans will see.",
        image: "Upload a clear picture of yourself or your band. This image will represent your artist identity on the platform.",
        preferredContactMethod: "Enter your best contact information. Select whether you'd like to provide a phone number, cell number, or both. This will be used for receipts, reminders, and may be used for venues to reach you regarding bookings.",
        location: "Provide your primary location (city or region) so that venues in your area can find you, and you can be recommended for gigs near you.",
        performanceType: "Select whether this account will be used for a solo artist or a band. This helps venues understand your performance setup and ensures that your profile reflects whether you are performing alone or with a group.",
        experienceLevel: "Select how long you've been performing live in front of audiences, relative to the account type. If registering as a band, indicate how many years the band has been performing together.",
        genre: "Select up to three genres that best represent your music (originals or covers).",
        preferredVenues: "Choose the types of venues where you prefer to perform. Select all that apply.",
        bio: "Share a short description of your music journey, style, and key experiences."
    };

    const genres = [
        'Rock',
        'Pop',
        'Jazz',
        'Blues',
        'Hip-Hop',
        'Country',
        'EDM (Electronic Dance Music)',
        'Folk',
        'Reggae',
        'Classical',
        'Indie',
        'R&B/Soul',
        'Metal',
        'World Music',
        'Other'
    ];

    const performanceTypes = [
        'Solo Act',
        'Duo',
        'Band',
        'DJ',
        'Ensemble'
    ];

    const experienceLevels = [
        'Beginner (1-3 years)',
        'Intermediate (3-5 years)',
        'Experienced (5-10 years)',
        'Professional (10+ years)'
    ];

    const venueTypes = [
        'Small Bar (Capacity under 100)',
        'Medium Bar (Capacity 100-300)',
        'Large Bar/Club (Capacity 300+)',
        'Outdoor Venue',
        'Restaurant',
        'Café',
        'Lounge'
    ];

    const handleChange = (event) => {
        const { name, type, value, files, checked } = event.target;
        if (type === 'file') {
            setPerformerData({ ...performerData, [name]: files[0] });
        } else if (type === 'checkbox') {
            const updatedVenues = checked
                ? [...performerData.preferredVenues, value]
                : performerData.preferredVenues.filter(venue => venue !== value);
            setPerformerData({ ...performerData, preferredVenues: updatedVenues });
        } else {
            setPerformerData({ ...performerData, [name]: value });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        for (const key in performerData) {
            if (key === 'preferredVenues') {
                formData.append(key, JSON.stringify(performerData[key]));
            } else {
                formData.append(key, performerData[key]);
            }
        }
        formData.append('type', 'performer');
        formData.append('location', `${city}, ${state}`);
        console.log(formData);
        try {
            const response = await axios.post('https://api.live-and-local.com/profile', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            });
            console.log('Profile Created:', response.data);
            alert('Profile created successfully!');
            navigate('/login');
        } catch (error) {
            console.error('Failed to create profile:', error);
            alert('Failed to create profile, please check the data and try again.');
            console.error('Failed to update profile:', error.response?.data);
        }
    };

    return (
        <Box mb={8}>
            <div className="wrapper">
                <form onSubmit={handleSubmit}>
                    <h1>Create Performer Profile</h1>
                    <div className="input-box">
                        <Tooltip title={instructions.name} arrow>
                            <InfoIcon className="info-icon" fontSize="small" />
                        </Tooltip>
                        <input type="text" name="name" value={performerData.name} onChange={handleChange} placeholder="Performer Name" required />
                       
                    </div>

                    <div className="input-box">
                        <Tooltip title={instructions.image} arrow>
                            <InfoIcon className="info-icon" fontSize="small" />
                        </Tooltip>
                        <input type="file" name="image" onChange={handleChange} placeholder="Profile Pic" />
                    </div>

                    <div className="input-box">
                        <Tooltip title={instructions.location} arrow>
                            <InfoIcon className="info-icon" fontSize="small" />
                        </Tooltip>
                        <label>
                            Location:
                            <input
                                type="text"
                                name="city"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                placeholder="City"
                                required
                            />
                            <select
                                id="state"
                                name="state"
                                onChange={(e) => setState(e.target.value)}
                                required
                            >
                                {stateAbbreviations.map(st => (
                                    <option key={st} value={st}>{st}</option>
                                ))}
                            </select>
                        </label>
                    </div>

                    <div className="input-box">
                        <Tooltip title={instructions.preferredContactMethod} arrow>
                            <InfoIcon className="info-icon" fontSize="small" />
                        </Tooltip>
                        <select name="preferredContactMethod" value={performerData.preferredContactMethod} onChange={handleChange} required>
                            <option value="" disabled>Preferred Contact Method</option>
                            <option value="email">Email</option>
                            <option value="phone">Phone</option>
                        </select>
                    </div>
                    
                    {performerData.preferredContactMethod === 'phone' && (
                        <div className="input-box">
                            <input type="tel" name="phoneNumber" value={performerData.phoneNumber} onChange={handleChange} placeholder="Phone Number" required />
                        </div>
                    )}

                    <div className="input-box">
                        <Tooltip title={instructions.performanceType} arrow>
                            <InfoIcon className="info-icon" fontSize="small" />
                        </Tooltip>
                        <select name="performanceType" value={performerData.performanceType} onChange={handleChange} required>
                            <option value="" disabled>Select Performance Type</option>
                            {performanceTypes.map((type, index) => (
                                <option key={index} value={type}>{type}</option>
                            ))}
                        </select>
                    </div>

                    <div className="input-box">
                        <Tooltip title={instructions.experienceLevel} arrow>
                            <InfoIcon className="info-icon" fontSize="small" />
                        </Tooltip>
                        <select name="experienceLevel" value={performerData.experienceLevel} onChange={handleChange} required>
                            <option value="" disabled>Select Experience Level</option>
                            {experienceLevels.map((level, index) => (
                                <option key={index} value={level}>{level}</option>
                            ))}
                        </select>
                    </div>

                    <div className="input-box">
                        <Tooltip title={instructions.genre} arrow>
                            <InfoIcon className="info-icon" fontSize="small" />
                        </Tooltip>
                        <select name="genre" value={performerData.genre} onChange={handleChange} required>
                            <option value="" disabled>Select Genre</option>
                            {genres.map((genre, index) => (
                                <option key={index} value={genre}>{genre}</option>
                            ))}
                        </select>
                    </div>

                    <div className="input-box dropdown-checkbox">
                        <Tooltip title={instructions.preferredVenues} arrow>
                            <InfoIcon className="info-icon" fontSize="small" />
                        </Tooltip>
                        <div className="dropdown-header" onClick={() => setDropdownOpen(!dropdownOpen)}>
                            Preferred Venue Types
                            <span className={`arrow ${dropdownOpen ? 'open' : ''}`}></span>
                        </div>

                        {dropdownOpen && (
                            <div className="dropdown-content">
                                {venueTypes.map((venue, index) => (
                                    <div key={index} className="checkbox-item">
                                        <input
                                            type="checkbox"
                                            id={`venue-${index}`}
                                            name="preferredVenues"
                                            value={venue}
                                            checked={performerData.preferredVenues.includes(venue)}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor={`venue-${index}`}>{venue}</label>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className="input-box">
                        <Tooltip title={instructions.bio} arrow>
                            <InfoIcon className="info-icon" fontSize="small" />
                        </Tooltip>
                        <textarea name="bio" value={performerData.bio} onChange={handleChange} placeholder="Bio" />
                    </div>

                    <button type="submit" className="btn">Create Profile</button>
                </form>
            </div>
        </Box>
    );
}

export default PerformerProfileForm;
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  Snackbar
} from '@mui/material';
import { Event, Schedule, Place, AttachMoney, MusicNote } from '@mui/icons-material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, TimePicker, DatePicker } from '@mui/x-date-pickers';
import axios from 'axios';

const EditBookingDashboard = ({ booking, onBack, onBookingUpdated }) => {
  // Initialize form data with properly mapped booking data
  console.log("HERE I AM THE BOOKING IS", booking);
  const [formData, setFormData] = useState({
    date: new Date(booking.date), // Convert string date to Date object
    startTime: new Date(`2000-01-01T${booking.startTime}`), // Convert time string to Date object
    endTime: new Date(`2000-01-01T${booking.endTime}`),
    location: booking.location || '',
    capacity: booking.capacity || '',
    performerType: booking.details?.performanceType || '',
    experienceLevel: booking.details?.experienceLevel || '',
    genres: booking.details?.genres || [],
    minCost: booking.details?.priceRange?.min || '',
    maxCost: booking.details?.priceRange?.max || '',
    venueType: Array.isArray(booking.venue_type) ? booking.venue_type[0] : booking.venue_type || ''
  });

  // Add useEffect to log the initial form data and booking object for debugging
  useEffect(() => {
    console.log('Initial Booking Data:', booking);
    console.log('Initial Form Data:', formData);
  }, []);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (field, value) => {
    console.log(`Updating ${field}:`, value); // Debug log
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSave = async () => {
    try {
      // Format the date and times for the API
      const formattedDate = formData.date.toISOString().split('T')[0];
      const formattedStartTime = formData.startTime.toTimeString().slice(0, 5);
      const formattedEndTime = formData.endTime.toTimeString().slice(0, 5);

      const response = await axios.put(`https://api.live-and-local.com/update-available-date/${booking.id}`, {
        date: formattedDate,
        start_time: formattedStartTime,
        end_time: formattedEndTime,
        location: formData.location,
        capacity: formData.capacity,
        performer_type: formData.performerType,
        experience_level: formData.experienceLevel,
        genre: formData.genres.join(', '),
        min_cost: formData.minCost,
        max_cost: formData.maxCost,
        venue_type: formData.venueType
      });

      if (response.data.success) {
        setShowSuccess(true);
        if (onBookingUpdated) {
          onBookingUpdated(response.data.booking);
        }
        setOpenConfirmation(false);
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to update booking');
      setOpenConfirmation(false);
    }
  };

  const handleCloseSuccess = () => setShowSuccess(false);
  const handleCloseError = () => setError(null);

  return (
    <Box sx={{ p: 3 }}>
      <Button onClick={onBack} variant="outlined" sx={{ mb: 2 }}>
        Back to Booking
      </Button>

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>Edit Booking</Typography>
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>Date and Time</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DatePicker
                    label="Date"
                    value={formData.date}
                    onChange={(newDate) => handleInputChange('date', newDate)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    label="Start Time"
                    value={formData.startTime}
                    onChange={(newTime) => handleInputChange('startTime', newTime)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    label="End Time"
                    value={formData.endTime}
                    onChange={(newTime) => handleInputChange('endTime', newTime)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>Venue Details</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Location"
                  value={formData.location}
                  onChange={(e) => handleInputChange('location', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Capacity"
                  type="number"
                  value={formData.capacity}
                  onChange={(e) => handleInputChange('capacity', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Venue Type</InputLabel>
                  <Select
                    value={formData.venueType}
                    onChange={(e) => handleInputChange('venueType', e.target.value)}
                    label="Venue Type"
                  >
                    <MenuItem value="bar">Bar</MenuItem>
                    <MenuItem value="restaurant">Restaurant</MenuItem>
                    <MenuItem value="club">Club</MenuItem>
                    <MenuItem value="concert_hall">Concert Hall</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Performance Requirements</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Performer Type</InputLabel>
                  <Select
                    value={formData.performerType}
                    onChange={(e) => handleInputChange('performerType', e.target.value)}
                    label="Performer Type"
                  >
                    <MenuItem value="solo">Solo</MenuItem>
                    <MenuItem value="band">Band</MenuItem>
                    <MenuItem value="dj">DJ</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Experience Level</InputLabel>
                  <Select
                    value={formData.experienceLevel}
                    onChange={(e) => handleInputChange('experienceLevel', e.target.value)}
                    label="Experience Level"
                  >
                    <MenuItem value="beginner">Beginner (1-3 years)</MenuItem>
                    <MenuItem value="intermediate">Intermediate (3-5 years)</MenuItem>
                    <MenuItem value="experienced">Experienced (5-10 years)</MenuItem>
                    <MenuItem value="professional">Professional (10+ years)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Minimum Price"
                  type="number"
                  value={formData.minCost}
                  onChange={(e) => handleInputChange('minCost', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Maximum Price"
                  type="number"
                  value={formData.maxCost}
                  onChange={(e) => handleInputChange('maxCost', e.target.value)}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => setOpenConfirmation(true)}
          sx={{ mr: 2 }}
        >
          Save Changes
        </Button>
        <Button variant="outlined" onClick={onBack}>
          Cancel
        </Button>
      </Box>

      <Dialog
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
      >
        <DialogTitle>Confirm Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save these changes to your booking? This will update the booking for all related contracts.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmation(false)}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            Confirm Changes
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={showSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
        <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
          Booking updated successfully!
        </Alert>
      </Snackbar>

      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditBookingDashboard;
import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Grid,
  Chip,
  Paper
} from '@mui/material';
import { 
  Event, 
  AccessTime, 
  Place, 
  MusicNote
} from '@mui/icons-material';
import axios from 'axios';

const ConfirmedGigs = () => {
  const [confirmedGigs, setConfirmedGigs] = useState([]);
  const [stats, setStats] = useState({
    total_gigs: 0,
    total_earnings: 0,
    average_per_gig: 0
  });
  
  useEffect(() => {
    fetchGigs();
  }, []);

  const fetchGigs = async () => {
    try {
      const response = await axios.post('https://api.live-and-local.com/getArtistBookedBookings', {}, { withCredentials: true });
      setConfirmedGigs(response.data.gigs);
      console.log(response.data.gigs);
      setStats(response.data.stats);
    } catch (error) {
      console.error('Error fetching confirmed gigs:', error);
    }
  };

  const StatCard = ({ title, value }) => (
    <Paper 
      elevation={0}
      sx={{
        p: 3,
        height: '100%',
        bgcolor: 'rgba(146, 74, 174, 0.04)',
        borderRadius: 2,
        border: '1px solid rgba(146, 74, 174, 0.1)',
        transform: 'translateY(0)',
        transition: 'all 0.2s ease',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 12px 24px -10px rgba(146, 74, 174, 0.15)',
          bgcolor: 'rgba(146, 74, 174, 0.08)',
        }
      }}
    >
      <Typography 
        variant="body1" 
        sx={{ 
          color: 'text.secondary',
          mb: 1,
          fontSize: '0.875rem'
        }}
      >
        {title}
      </Typography>
      <Typography 
        variant="h4" 
        sx={{ 
          color: '#924AAE',
          fontWeight: 500,
          letterSpacing: '-0.02em'
        }}
      >
        {value}
      </Typography>
    </Paper>
  );
  
  const GigCard = ({ gig }) => {
    const isPastGig = new Date(gig.date) < new Date();
    
    const getHoverStyles = () => {
      if (isPastGig) {
        return {
          transform: 'translateY(-4px)',
          boxShadow: '0 12px 24px -10px rgba(239, 68, 68, 0.2)',
          borderColor: 'rgba(239, 68, 68, 0.3)',
          bgcolor: 'rgba(239, 68, 68, 0.02)',
        };
      }
      return {
        transform: 'translateY(-4px)',
        boxShadow: '0 12px 24px -10px rgba(34, 197, 94, 0.2)',
        borderColor: 'rgba(34, 197, 94, 0.3)',
        bgcolor: 'rgba(34, 197, 94, 0.02)',
      };
    };

    return (
      <Paper 
        elevation={0}
        sx={{
          mb: 2,
          borderRadius: 2,
          border: '1px solid rgba(0, 0, 0, 0.08)',
          overflow: 'hidden',
          transform: 'translateY(0)',
          transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
          cursor: 'pointer',
          borderLeft: isPastGig ? '4px solid rgb(239, 68, 68)' : '4px solid rgb(34, 197, 94)',
          '&:hover': getHoverStyles()
        }}
      >
        <Box sx={{ p: 3 }}>
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mb: 3
            }}
          >
            <Typography 
              variant="h5" 
              sx={{ 
                fontWeight: 500,
                color: isPastGig ? 'rgb(239, 68, 68)' : 'rgb(34, 197, 94)',
                letterSpacing: '-0.02em'
              }}
            >
              {gig.name}
            </Typography>
            <Chip 
              label={gig.status ? 'Contract Signed' : 'Pending Signature'} 
              size="small"
              sx={{ 
                bgcolor: gig.status
                  ? 'rgba(46, 160, 67, 0.1)' 
                  : 'rgba(219, 171, 9, 0.1)',
                color: gig.status
                  ? 'rgb(46, 160, 67)' 
                  : 'rgb(219, 171, 9)',
                border: 'none',
                borderRadius: '6px',
                height: '24px',
                '& .MuiChip-label': {
                  px: 1.5,
                  fontSize: '0.75rem',
                  fontWeight: 500
                }
              }}
            />
          </Box>

          <Grid container spacing={4} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Event sx={{ color: isPastGig ? 'rgb(239, 68, 68)' : 'rgb(34, 197, 94)', mr: 1.5, fontSize: 20 }} />
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {new Date(gig.date).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                  })}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AccessTime sx={{ color: isPastGig ? 'rgb(239, 68, 68)' : 'rgb(34, 197, 94)', mr: 1.5, fontSize: 20 }} />
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {gig.start_time} - {gig.end_time}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Place sx={{ color: isPastGig ? 'rgb(239, 68, 68)' : 'rgb(34, 197, 94)', mr: 1.5, fontSize: 20 }} />
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {gig.location}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <MusicNote sx={{ color: isPastGig ? 'rgb(239, 68, 68)' : 'rgb(34, 197, 94)', mr: 1.5, fontSize: 20 }} />
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {gig.artistName}'s Performance
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'flex-end',
              pt: 2,
              borderTop: '1px solid rgba(0, 0, 0, 0.06)'
            }}
          >
            <Typography 
              variant="h6" 
              sx={{ 
                fontWeight: 500,
                color: 'text.primary',
                letterSpacing: '-0.02em'
              }}
            >
              ${gig.bid_amount}
            </Typography>
          </Box>
        </Box>
      </Paper>
    );
  };

  return (
    <Box 
      sx={{ 
        mx: 'auto', 
        p: { xs: 2, sm: 3, md: 4 },
        bgcolor: '#ffffff'
      }}
    >
      <Box sx={{ mb: 6, maxWidth: 720 }}>
        <Typography 
          variant="h3" 
          sx={{ 
            mb: 2, 
            fontWeight: 600,
            color: 'text.primary',
            letterSpacing: '-0.02em'
          }}
        >
          Confirmed Gigs
        </Typography>
        <Typography 
          variant="body1" 
          sx={{ 
            color: 'text.secondary',
            lineHeight: 1.6
          }}
        >
          Your upcoming performances and events
        </Typography>
      </Box>

      <Grid container spacing={3} sx={{ mb: 6 }}>
        <Grid item xs={12} sm={4}>
          <StatCard 
            title="Total Gigs"
            value={stats.total_gigs}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatCard 
            title="Total Earnings"
            value={`$${stats.total_earnings}`}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatCard 
            title="Average Per Gig"
            value={`$${stats.average_per_gig}`}
          />
        </Grid>
      </Grid>

      <Box>
        {confirmedGigs.map(gig => (
          <GigCard key={gig.id} gig={gig} />
        ))}
      </Box>
    </Box>
  );
};

export default ConfirmedGigs;
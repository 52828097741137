import React from 'react';
import { 
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  styled
} from '@mui/material';
import { Link } from 'react-router-dom';
import { LocationOn, People, Event } from '@mui/icons-material';

const EnhancedFeatureCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  height: 400,
  borderRadius: theme.spacing(4),
  backgroundColor: theme.palette.primary.main,
  overflow: 'hidden',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[10],
    '& .action-button': {
      transform: 'translateY(0)',
      opacity: 1,
    }
  }
}));

const ImageOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(to bottom, rgba(108, 99, 255, 0.3), rgba(108, 99, 255, 0.9))', // Updated to match new purple theme
  }
});

const ContentWrapper = styled(CardContent)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(4),
  '&:last-child': {
    paddingBottom: theme.spacing(4),
  }
}));

const Tag = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  borderRadius: 20,
  marginBottom: theme.spacing(2),
}));

const ActionButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1.5),
  backgroundColor: '#fff',
  color: theme.palette.primary.main,
  transform: 'translateY(20px)',
  opacity: 0,
  transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  }
}));

const HeaderBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginBottom: '16px'
});

const VenueDetail = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: 'white',
  marginBottom: theme.spacing(1),
}));

const EnhancedVenueCard = ({ venue, isFeatureCard = false }) => {
  if (!venue) return null;

  const {
    id,
    name,
    match_score,
    location,
    image_url,
    capacity,
    event_types = [],
    booking_rate,
    upcoming_dates = []
  } = venue;

  const nextAvailableDate = upcoming_dates[0]?.date;
  const formattedDate = nextAvailableDate 
    ? new Date(nextAvailableDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
    : 'Flexible';

  return (
    <EnhancedFeatureCard elevation={4}>
      <ImageOverlay>
        <Box
          component="img"
          src={image_url || '/default-venue-image.jpg'}
          alt={`${name}'s venue`}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: 0.2,
          }}
        />
      </ImageOverlay>
      
      <ContentWrapper>
        {/* Top Section */}
        <Box>
          <HeaderBox>
            <Tag variant="overline" color="white" sx={{ mb: 0 }}>
              {isFeatureCard ? 'PERFECT MATCH' : 'BOOKING AVAILABLE'}
            </Tag>
            <Typography 
              variant="overline" 
              sx={{ 
                color: 'white',
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                padding: '4px 16px',
                borderRadius: '20px',
                fontWeight: 500
              }}
            >
              {event_types[0]?.toUpperCase() || 'LIVE MUSIC'}
            </Typography>
          </HeaderBox>
          
          <Typography variant="h4" component="h3" sx={{ 
            color: 'white',
            mb: 2,
            fontWeight: 600 
          }}>
            {name}
          </Typography>
          
          <VenueDetail>
            <LocationOn sx={{ fontSize: 20 }} />
            <Typography variant="body1">
              {location || 'Location not specified'}
            </Typography>
          </VenueDetail>

          <VenueDetail>
            <People sx={{ fontSize: 20 }} />
            <Typography variant="body1">
              Capacity: {capacity || 'Not specified'}
            </Typography>
          </VenueDetail>

          <VenueDetail>
            <Event sx={{ fontSize: 20 }} />
            <Typography variant="body1">
              Next Available: {formattedDate}
            </Typography>
          </VenueDetail>
        </Box>
        
        <Box>
          <ActionButton 
            variant="contained" 
            className="action-button"
            component={Link}
            to={`/view_profile/${id}`}
          >
            View Venue Details
          </ActionButton>
          
          <Typography variant="h5" sx={{ 
            color: 'white',
            fontWeight: 500,
            mt: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <span>{match_score}% Match</span>
            
            <span>{booking_rate ? `$${booking_rate}/hr` : ' Contact'}</span>
          </Typography>
        </Box>
      </ContentWrapper>
    </EnhancedFeatureCard>
  );
};

export default EnhancedVenueCard;
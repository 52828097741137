import React, { useState } from 'react'; 
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    Grid,
    Paper,
    List,
    ListItem,
    ListItemText,
    Divider,
    Chip,
    Box,
    Snackbar,
    Alert,
    InputAdornment,
    IconButton,
} from '@mui/material';
import {
    Event,
    Schedule,
    Place,
    AttachMoney,
    Edit as EditIcon,
    Close as CloseIcon,
    MusicNote,
} from '@mui/icons-material';

const ArtistBookingBidPopup = ({ booking, onClose }) => {
    const [bidAmount, setBidAmount] = useState('');
    const [technicalRequirements, setTechnicalRequirements] = useState('');
    const [artistNotes, setArtistNotes] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleBidSubmit = async () => {
        const requestData = {
            bookingId: booking.booking_id || booking.id,
            bidAmount: bidAmount,
            artistNotes: artistNotes,
            technicalRequirements: technicalRequirements,
            venue_id: booking.venue_user_id || booking.venueProfile.id,
            eventDetails: {
                venueName: booking.venue_name,
                date: booking.date,
                startTime: booking.start_time,
                endTime: booking.end_time,
                location: booking.location
            }
        };
        
        try {
            const bidResponse = await fetch('https://api.live-and-local.com/api/submit-bid', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
                credentials: 'include',
            });
            
            if (!bidResponse.ok) {
                throw new Error('Failed to submit bid');
            }
    
            const responseData = await bidResponse.json();
            setOpenSnackbar(true);
            setTimeout(() => {
                onClose();
            }, 2000);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'available':
                return 'success';
            default:
                return 'default';
        }
    };

    return (
        <>
            <Dialog 
                open={true} 
                onClose={onClose} 
                maxWidth="md" 
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: '24px',
                        padding: 2
                    }
                }}
            >
                
                <DialogContent dividers>
                    <Paper 
                        elevation={0}
                        sx={{
                            borderRadius: '24px',
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            backdropFilter: 'blur(20px)',
                            border: '1px solid rgba(239, 97, 145, 0.1)',
                            padding: { xs: 3, sm: 4, md: 6 },
                            mb: 4,
                            position: 'relative',
                            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                height: '6px',
                                background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                                borderRadius: '24px 24px 0 0'
                            }
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8}>
                                <Typography 
                                    variant="h3" 
                                    sx={{
                                        fontWeight: 800,
                                        background: 'linear-gradient(135deg, #EF6191 0%, #FF8FB1 100%)',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        mb: 2
                                    }}
                                >
                                    {booking.venue_name || booking.venueName}
                                </Typography>
                                <Typography variant="h6" color="text.secondary" sx={{ mb: 3 }}>
                                    {booking.location}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                                <Chip 
                                    label="Available" 
                                    color={getStatusColor('Available')}
                                    sx={{
                                        fontSize: '1rem',
                                        padding: '20px 16px',
                                        borderRadius: '12px',
                                        fontWeight: 600,
                                        backgroundColor: '#EF6191',
                                        '& .MuiChip-label': {
                                            padding: '0 8px'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={4} sx={{ mt: 2 }}>
                            <Grid item xs={12} md={6}>
                                <Paper 
                                    elevation={0}
                                    sx={{ 
                                        p: 3, 
                                        height: '100%',
                                        borderRadius: '16px',
                                        backgroundColor: 'rgba(239, 97, 145, 0.05)',
                                        border: '1px solid rgba(239, 97, 145, 0.1)'
                                    }}
                                >
                                    <Typography variant="h6" sx={{ mb: 3, color: '#EF6191', fontWeight: 600 }}>
                                        Event Details
                                    </Typography>
                                    <List>
                                        <ListItem sx={{ px: 0 }}>
                                            <Event sx={{ mr: 2, color: '#EF6191' }} />
                                            <ListItemText 
                                                primary={<Typography sx={{ fontWeight: 600 }}>Date</Typography>}
                                                secondary={new Date(booking.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                                            />
                                        </ListItem>
                                        <ListItem sx={{ px: 0 }}>
                                            <Schedule sx={{ mr: 2, color: '#EF6191' }} />
                                            <ListItemText 
                                                primary={<Typography sx={{ fontWeight: 600 }}>Time</Typography>}
                                                secondary={`${booking.start_time} - ${booking.end_time}`}
                                            />
                                        </ListItem>
                                        <ListItem sx={{ px: 0 }}>
                                            <Place sx={{ mr: 2, color: '#EF6191' }} />
                                            <ListItemText 
                                                primary={<Typography sx={{ fontWeight: 600 }}>Venue</Typography>}
                                                secondary={booking.location}
                                            />
                                        </ListItem>
                                        <ListItem sx={{ px: 0 }}>
                                            <AttachMoney sx={{ mr: 2, color: '#EF6191' }} />
                                            <ListItemText 
                                                primary={<Typography sx={{ fontWeight: 600 }}>Minimum Price</Typography>}
                                                secondary={`$${booking.min_cost}`}
                                            />
                                        </ListItem>
                                    </List>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Paper 
                                    elevation={0}
                                    sx={{ 
                                        p: 3, 
                                        height: '100%',
                                        borderRadius: '16px',
                                        backgroundColor: 'rgba(239, 97, 145, 0.05)',
                                        border: '1px solid rgba(239, 97, 145, 0.1)'
                                    }}
                                >
                                    <Typography variant="h6" sx={{ mb: 3, color: '#EF6191', fontWeight: 600 }}>
                                        Performance Requirements
                                    </Typography>
                                    <List>
                                        <ListItem sx={{ px: 0 }}>
                                            <ListItemText 
                                                primary={<Typography sx={{ fontWeight: 600 }}>Venue Type</Typography>}
                                                secondary={booking.venue_type.replace(/[\[\]"]/g, '').split(",").join(", ")}
                                            />
                                        </ListItem>
                                        <ListItem sx={{ px: 0 }}>
                                            <ListItemText 
                                                primary={<Typography sx={{ fontWeight: 600 }}>Experience Level</Typography>}
                                                secondary={booking.experience_level}
                                            />
                                        </ListItem>
                                        <ListItem sx={{ px: 0 }}>
                                            <ListItemText 
                                                primary={<Typography sx={{ fontWeight: 600 }}>Genres Preferred</Typography>}
                                                secondary={booking.genre}
                                            />
                                        </ListItem>
                                        <ListItem sx={{ px: 0 }}>
                                            <ListItemText 
                                                primary={<Typography sx={{ fontWeight: 600 }}>Capacity</Typography>}
                                                secondary={booking.capacity}
                                            />
                                        </ListItem>
                                    </List>
                                </Paper>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper 
                                elevation={0}
                                sx={{ 
                                    p: 3, 
                                    borderRadius: '16px',
                                    backgroundColor: 'rgba(239, 97, 145, 0.05)',
                                    border: '1px solid rgba(239, 97, 145, 0.1)',
                                    mt: 4
                                }}
                            >
                                <Typography variant="h6" sx={{ mb: 3, color: '#EF6191', fontWeight: 600 }}>
                                    Your Proposal
                                </Typography>
                                <Box sx={{ mt: 2 }}>
                                    <TextField
                                        fullWidth
                                        label="Your Bid Amount"
                                        type="number"
                                        value={bidAmount}
                                        onChange={(e) => setBidAmount(e.target.value)}
                                        InputProps={{ 
                                            startAdornment: <InputAdornment position="start">$</InputAdornment> 
                                        }}
                                        sx={{ 
                                            mb: 3,
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '12px',
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#EF6191'
                                                }
                                            }
                                        }}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Technical Requirements"
                                        multiline
                                        rows={3}
                                        value={technicalRequirements}
                                        onChange={(e) => setTechnicalRequirements(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <EditIcon color="action" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{ mb: 3 }}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Additional Notes"
                                        multiline
                                        rows={4}
                                        value={artistNotes}
                                        onChange={(e) => setArtistNotes(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <EditIcon color="action" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            </Paper>
                        </Grid>

                        <Box sx={{ mt: 4, display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                            <Button 
                                variant="contained" 
                                onClick={onClose}
                                sx={{
                                    background: '#ccc',
                                    color: '#fff',
                                    borderRadius: '12px',
                                    padding: '12px 24px',
                                    fontSize: '1rem',
                                    fontWeight: 600,
                                    textTransform: 'none',
                                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                    '&:hover': {
                                        background: '#bbb',
                                        boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
                                        transform: 'translateY(-1px)'
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                            <Button 
                                variant="contained" 
                                onClick={handleBidSubmit}
                                disabled={!bidAmount}
                                sx={{
                                    background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                                    color: '#fff',
                                    borderRadius: '12px',
                                    padding: '12px 24px',
                                    fontSize: '1rem',
                                    fontWeight: 600,
                                    textTransform: 'none',
                                    boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
                                    '&:hover': {
                                        background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                                        boxShadow: '0 6px 16px rgba(239, 97, 145, 0.4)',
                                        transform: 'translateY(-1px)'
                                    }
                                }}
                            >
                                Submit Bid
                            </Button>
                        </Box>
                    </Paper>
                </DialogContent>
            </Dialog>

            <Snackbar 
                open={openSnackbar} 
                autoHideDuration={6000} 
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleCloseSnackbar} 
                    severity="success"
                    sx={{ 
                        minWidth: '300px',
                        borderRadius: '16px',
                        backgroundColor: '#EF6191',
                        color: '#fff',
                        boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
                        '& .MuiAlert-icon': {
                            color: '#fff'
                        },
                        '& .MuiAlert-action': {
                            color: '#fff'
                        }
                    }}
                    icon={<MusicNote sx={{ fontSize: '24px' }} />}
                >
                    <Box>
                        <Typography sx={{ fontWeight: 600, mb: 0.5 }}>
                            Bid Submitted!
                        </Typography>
                        <Typography variant="body2">
                            Your bid has been successfully submitted!
                        </Typography>
                    </Box>
                </Alert>
            </Snackbar>
        </>
    );
};

export default ArtistBookingBidPopup;
import React from 'react';
import Particles from 'react-tsparticles';
import styled from 'styled-components';

const UnifiedContainer = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  background: 
    radial-gradient(circle at 20% 30%, rgba(239, 97, 145, 0.15) 0%, transparent 50%),
    radial-gradient(circle at 80% 70%, rgba(41, 98, 255, 0.15) 0%, transparent 50%);

  &::before {
    content: '';
    position: fixed;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle at center, rgba(255, 255, 255, 0.05), transparent 70%);
    animation: rotateBG 30s linear infinite;
    z-index: 0;
  }

  @keyframes rotateBG {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
`;

const ParticlesWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export default function UnifiedHome({ children }) {
  const particlesInit = async (main) => {
    // Particle initialization if needed
  };

  return (
    <UnifiedContainer>
      <ParticlesWrapper>
        <Particles
          id="tsparticles"
          init={particlesInit}
          options={{
            background: {
              color: 'transparent',
            },
            fpsLimit: 60,
            particles: {
              color: { value: '#ffffff' },
              links: {
                color: '#ffffff',
                distance: 150,
                enable: true,
                opacity: 0.3,
                width: 1,
              },
              move: {
                enable: true,
                speed: 1,
              },
              number: {
                value: 50,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: 'circle',
              },
              size: {
                value: { min: 1, max: 3 },
              },
            },
            detectRetina: true,
          }}
        />
      </ParticlesWrapper>
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </UnifiedContainer>
  );
}
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AddressConfirmationModal from './AddressConfirmationModal';
import {
  Box,
  Typography,
  TextField,
  Button,
  Container,
  Paper,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Slider,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Grid,
  Snackbar,
  Alert,
  Avatar,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
    Info as InfoIcon,
    PhotoCamera as PhotoCameraIcon,
    LocationOn as LocationOnIcon,
    Phone as PhoneIcon,
    Email as EmailIcon,
    Close as CloseIcon,
    CheckCircle as CheckCircleIcon,
  } from '@mui/icons-material';
  import './Instrution.css';

function VendorProfileForm() {
  const [vendorData, setVendorData] = useState({
    name: '',
    image: null,
    additionalImages: [],
    settings: [],
    stageTypes: [],
    capacity: 50,
    full_address: '',
    latitude: null,
    longitude: null,
    bio: '',
    amenities: '',
    phoneNumber: '',
    preferredContactMethod: '',
  });
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [additionalImagePreviews, setAdditionalImagePreviews] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const navigate = useNavigate();

  const instructions = {
    name: 'Enter the name of your bar or venue as you would like it to appear on your profile.',
    preferredContactMethod:
      "Select whether you'd like to provide a phone number, cell number, or both.",
    location:
      'Please search for your location and confirm your address to add your venue to the map.',
    image:
      'Upload clear, high-quality images of the outside and inside of your venue (2-3 images).',
    settings:
      "Describe your venue's setting (indoor, outdoor, or a combination of both).",
    stageTypes:
      'Select the option that best describes your stage setup. Feel free to select all that apply.',
    capacity:
      'Use the slider to select a value that reflects your venue’s capacity.',
    bio: 'Tell us more about your venue’s atmosphere and what makes it special.',
    other_notes:
      'Include any additional notes or details about your venue that might be important for artists.',
  };

  const settingOptions = [
    'Bar',
    'Nightclub',
    'Café',
    'Restaurant',
    'Lounge',
    'Concert Hall',
    'Outdoor Space',
    'Brewery/Tavern',
    'Private Event Space',
    'Other',
  ];

  const stageOptions = [
    'Small (1-2 performers)',
    'Medium (3-4 performers)',
    'Large (4+ performers)',
    'Adjustable Stage',
    'No Dedicated Stage',
    'Outdoor Stage Area',
  ];

  const handleAddressConfirmation = () => {
    setIsAddressModalOpen(true);
  };

  const handleConfirmAddress = (address, coordinates) => {
    setVendorData({
      ...vendorData,
      full_address: address,
      latitude: coordinates.lat,
      longitude: coordinates.lng,
    });
    setIsAddressModalOpen(false);
  };

  const handleChange = (event) => {
    const { name, type, value, files, checked } = event.target;

    if (type === 'file') {
      if (name === 'image') {
        const file = files[0];
        setVendorData({ ...vendorData, [name]: file });

        if (file) {
          setProfileImagePreview(URL.createObjectURL(file));
        } else {
          setProfileImagePreview(null);
        }
      } else if (name === 'additionalImages') {
        const newFiles = Array.from(files);
        setVendorData((prevData) => ({
          ...prevData,
          additionalImages: [...prevData.additionalImages, ...newFiles],
        }));

        const newPreviews = newFiles.map((file) => URL.createObjectURL(file));
        setAdditionalImagePreviews((prevPreviews) => [
          ...prevPreviews,
          ...newPreviews,
        ]);
      }
    } else if (type === 'checkbox') {
      const field = settingOptions.includes(value) ? 'settings' : 'stageTypes';
      const updatedOptions = checked
        ? [...vendorData[field], value]
        : vendorData[field].filter((option) => option !== value);
      setVendorData({ ...vendorData, [field]: updatedOptions });
    } else if (name === 'capacity') {
      setVendorData({ ...vendorData, [name]: value });
    } else {
      setVendorData({ ...vendorData, [name]: value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    for (const key in vendorData) {
      if (key === 'settings' || key === 'stageTypes') {
        formData.append(key, JSON.stringify(vendorData[key]));
      } else if (key === 'latitude' || key === 'longitude') {
        formData.append(
          key,
          vendorData[key] !== null ? vendorData[key].toString() : ''
        );
      } else if (key === 'additionalImages') {
        vendorData.additionalImages.forEach((file) => {
          formData.append('additional_images', file);
        });
      } else {
        formData.append(key, vendorData[key]);
      }
    }

    formData.append('type', 'vendor');

    try {
      const response = await axios.post(
        'https://api.live-and-local.com/profile',
        formData,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log('Profile Created:', response.data);
      setShowSuccessDialog(true);
    } catch (error) {
      console.error('Failed to create profile:', error);
      setShowError(true);
    }
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(false);
    navigate('/login');
  };

  useEffect(() => {
    return () => {
      if (profileImagePreview) URL.revokeObjectURL(profileImagePreview);
      additionalImagePreviews.forEach((src) => URL.revokeObjectURL(src));
    };
  }, [profileImagePreview, additionalImagePreviews]);

  return (
    <Box
      sx={{
        background:
          'linear-gradient(135deg, rgba(239, 97, 145, 0.05) 0%, rgba(255, 255, 255, 0.9) 100%)',
        padding: { xs: 2, sm: 4, md: 6 },
        position: 'relative',
        minHeight: '100vh',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '300px',
          background:
            'linear-gradient(180deg, rgba(239, 97, 145, 0.08) 0%, rgba(255, 255, 255, 0) 100%)',
          zIndex: 0,
        },
      }}
    >
      <Container
        maxWidth="md"
        sx={{ position: 'relative', zIndex: 1, mt: { xs: 4, sm: 6, md: 8 } }}
      >
        <Paper
          elevation={0}
          sx={{
            borderRadius: '24px',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(20px)',
            border: '1px solid rgba(239, 97, 145, 0.1)',
            padding: { xs: 3, sm: 4, md: 6 },
            position: 'relative',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '6px',
              background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
              borderRadius: '24px 24px 0 0',
            },
          }}
        >
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 800,
                background: 'linear-gradient(135deg, #EF6191 0%, #FF8FB1 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                mb: 2,
              }}
            >
              Create Vendor Profile
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Let's get started with your venue details
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Venue Name */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Venue Name"
                  name="name"
                  value={vendorData.name}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title={instructions.name} arrow>
                        <IconButton>
                          <InfoIcon sx={{ color: '#EF6191' }} />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#EF6191',
                      },
                    },
                  }}
                />
              </Grid>

              {/* Preferred Contact Method */}
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#EF6191',
                      },
                    },
                  }}
                >
                  <InputLabel id="preferred-contact-label">
                    Preferred Contact Method
                  </InputLabel>
                  <Select
                    labelId="preferred-contact-label"
                    label="Preferred Contact Method"
                    name="preferredContactMethod"
                    value={vendorData.preferredContactMethod}
                    onChange={handleChange}
                    endAdornment={
                      <Tooltip title={instructions.preferredContactMethod} arrow>
                        <IconButton>
                          <InfoIcon sx={{ color: '#EF6191' }} />
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    <MenuItem value="email">Email</MenuItem>
                    <MenuItem value="phone">Phone</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Phone Number */}
              {vendorData.preferredContactMethod === 'phone' && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phoneNumber"
                    value={vendorData.phoneNumber}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneIcon sx={{ color: '#EF6191' }} />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '12px',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#EF6191',
                        },
                      },
                    }}
                  />
                </Grid>
              )}

              {/* Address Confirmation */}
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleAddressConfirmation}
                  sx={{
                    borderColor: '#EF6191',
                    color: '#EF6191',
                    borderRadius: '12px',
                    padding: '12px',
                    textTransform: 'none',
                    '&:hover': {
                      borderColor: '#FF8FB1',
                      backgroundColor: 'rgba(239, 97, 145, 0.05)',
                    },
                  }}
                  startIcon={<LocationOnIcon />}
                  endIcon={
                    <Tooltip title={instructions.location} arrow>
                      <IconButton>
                        <InfoIcon sx={{ color: '#EF6191' }} />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  Add Address to Map
                </Button>
                {vendorData.full_address && (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Selected Address: {vendorData.full_address}
                  </Typography>
                )}
              </Grid>

              {/* Profile Image */}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  component="label"
                  fullWidth
                  sx={{
                    background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                    color: '#fff',
                    borderRadius: '12px',
                    padding: '12px',
                    textTransform: 'none',
                    '&:hover': {
                      background:
                        'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                      boxShadow: '0 6px 16px rgba(239, 97, 145, 0.4)',
                      transform: 'translateY(-1px)',
                    },
                  }}
                  startIcon={<PhotoCameraIcon />}
                  endIcon={
                    <Tooltip title={instructions.image} arrow>
                      <IconButton>
                        <InfoIcon sx={{ color: '#fff' }} />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  Upload Profile Image
                  <input
                    type="file"
                    hidden
                    name="image"
                    onChange={handleChange}
                    accept="image/*"
                  />
                </Button>
                {profileImagePreview && (
                  <Box sx={{ mt: 2, textAlign: 'center' }}>
                    <Avatar
                      src={profileImagePreview}
                      alt="Profile Preview"
                      sx={{ width: 100, height: 100, margin: '0 auto' }}
                    />
                  </Box>
                )}
              </Grid>

              {/* Additional Images */}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  component="label"
                  fullWidth
                  sx={{
                    background: '#ccc',
                    color: '#fff',
                    borderRadius: '12px',
                    padding: '12px',
                    textTransform: 'none',
                    '&:hover': {
                      background: '#bbb',
                    },
                  }}
                  startIcon={<PhotoCameraIcon />}
                  endIcon={
                    <Tooltip title={instructions.image} arrow>
                      <IconButton>
                        <InfoIcon sx={{ color: '#fff' }} />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  Upload Additional Images
                  <input
                    type="file"
                    hidden
                    name="additionalImages"
                    onChange={handleChange}
                    accept="image/*"
                    multiple
                  />
                </Button>
                {additionalImagePreviews.length > 0 && (
                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    {additionalImagePreviews.map((src, index) => (
                      <Grid item xs={4} key={index}>
                        <Avatar
                          src={src}
                          alt={`Additional Preview ${index + 1}`}
                          variant="rounded"
                          sx={{ width: '100%', height: 'auto' }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>

              {/* Settings Checkboxes */}
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <Typography variant="h6" sx={{ color: '#EF6191', mb: 2 }}>
                    Venue Settings
                    <Tooltip title={instructions.settings} arrow>
                      <IconButton>
                        <InfoIcon sx={{ color: '#EF6191' }} />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                  <FormGroup row>
                    {settingOptions.map((option) => (
                      <FormControlLabel
                        key={option}
                        control={
                          <Checkbox
                            checked={vendorData.settings.includes(option)}
                            onChange={handleChange}
                            name={option}
                            value={option}
                            sx={{
                              color: '#EF6191',
                              '&.Mui-checked': {
                                color: '#EF6191',
                              },
                            }}
                          />
                        }
                        label={option}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>

              {/* Stage Types Checkboxes */}
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <Typography variant="h6" sx={{ color: '#EF6191', mb: 2 }}>
                    Stage Types
                    <Tooltip title={instructions.stageTypes} arrow>
                      <IconButton>
                        <InfoIcon sx={{ color: '#EF6191' }} />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                  <FormGroup row>
                    {stageOptions.map((option) => (
                      <FormControlLabel
                        key={option}
                        control={
                          <Checkbox
                            checked={vendorData.stageTypes.includes(option)}
                            onChange={handleChange}
                            name={option}
                            value={option}
                            sx={{
                              color: '#EF6191',
                              '&.Mui-checked': {
                                color: '#EF6191',
                              },
                            }}
                          />
                        }
                        label={option}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>

              {/* Capacity Slider */}
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ color: '#EF6191', mb: 2 }}>
                  Venue Capacity: {vendorData.capacity}
                  <Tooltip title={instructions.capacity} arrow>
                    <IconButton>
                      <InfoIcon sx={{ color: '#EF6191' }} />
                    </IconButton>
                  </Tooltip>
                </Typography>
                <Slider
                  name="capacity"
                  value={vendorData.capacity}
                  onChange={handleChange}
                  min={0}
                  max={500}
                  step={50}
                  marks
                  valueLabelDisplay="auto"
                  sx={{
                    color: '#EF6191',
                    '& .MuiSlider-thumb': {
                      borderRadius: '50%',
                    },
                  }}
                />
              </Grid>

              {/* Bio */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Bio"
                  name="bio"
                  value={vendorData.bio}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title={instructions.bio} arrow>
                        <IconButton>
                          <InfoIcon sx={{ color: '#EF6191' }} />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#EF6191',
                      },
                    },
                  }}
                />
              </Grid>

              {/* Other Notes */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Other Notes"
                  name="amenities"
                  value={vendorData.amenities}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title={instructions.other_notes} arrow>
                        <IconButton>
                          <InfoIcon sx={{ color: '#EF6191' }} />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#EF6191',
                      },
                    },
                  }}
                />
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                    color: '#fff',
                    borderRadius: '12px',
                    padding: '12px 24px',
                    fontSize: '1rem',
                    fontWeight: 600,
                    textTransform: 'none',
                    boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
                    '&:hover': {
                      background:
                        'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                      boxShadow: '0 6px 16px rgba(239, 97, 145, 0.4)',
                      transform: 'translateY(-1px)',
                    },
                  }}
                >
                  Create Profile
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>

      {/* Address Confirmation Modal */}
      <AddressConfirmationModal
        isOpen={isAddressModalOpen}
        onClose={() => setIsAddressModalOpen(false)}
        onConfirm={handleConfirmAddress}
        initialAddress={vendorData.full_address}
      />

<Dialog
        open={showSuccessDialog}
        onClose={handleCloseSuccessDialog}
        PaperProps={{
          sx: {
            borderRadius: '24px',
            padding: 2,
          },
        }}
        aria-labelledby="success-dialog-title"
      >
        <DialogTitle sx={{ color: '#EF6191', fontWeight: 600 }}>
          Profile Created Successfully
        </DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <CheckCircleIcon sx={{ fontSize: 64, color: '#EF6191' }} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Your vendor profile has been created successfully!
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              You can now log in and start using our services.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
          <Button
            onClick={handleCloseSuccessDialog}
            variant="contained"
            sx={{
              background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
              color: '#fff',
              borderRadius: '12px',
              padding: '12px 24px',
              fontSize: '1rem',
              fontWeight: 600,
              textTransform: 'none',
              boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
              '&:hover': {
                background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                boxShadow: '0 6px 16px rgba(239, 97, 145, 0.4)',
                transform: 'translateY(-1px)',
              },
            }}
          >
            Proceed to Login
          </Button>
        </DialogActions>
      </Dialog>

      {/* Error Snackbar */}
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setShowError(false)}
          severity="error"
          sx={{
            minWidth: '300px',
            borderRadius: '16px',
            backgroundColor: '#EF6191',
            color: '#fff',
            boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
            '& .MuiAlert-icon': {
              color: '#fff',
            },
            '& .MuiAlert-action': {
              color: '#fff',
            },
          }}
          icon={<CloseIcon sx={{ fontSize: '24px' }} />}
        >
          <Box>
            <Typography sx={{ fontWeight: 600, mb: 0.5 }}>
              Profile Creation Failed!
            </Typography>
            <Typography variant="body2">
              Please check your inputs and try again.
            </Typography>
          </Box>
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default VendorProfileForm;
import React, { useState } from 'react';
import './AuthStyles.css';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom'; // Import useNavigate
import {
    Box,
    Typography,
    TextField,
    Button,
    Container,
    Paper,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Snackbar,
    Alert,
} from '@mui/material';
import {
    Person as PersonIcon,
    Email as EmailIcon,
    Lock as LockIcon,
    Close as CloseIcon,
} from '@mui/icons-material';

function Register() {
    const [userData, setUserData] = useState({
        username: '',
        password: '',
        email: '',
        user_type: 'vendor' // default set to 'vendor'
    });
    const navigate = useNavigate(); // Hook for navigation
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleChange = (event) => {
        setUserData({ ...userData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://api.live-and-local.com/register', userData);
            if (response.status === 201) {
                console.log('Registration Response:', response.data);
                console.log('Profile, ID: ', response.data.user_id)
                navigate(`/${response.data.user_type}-profile-form`, { state: { userId: response.data.user_id }});
            }
        } catch (error) {
            console.error('Registration Failed:', error.response?.data);
            const message = error.response?.data?.error || 'Registration failed. Please try again.';
            setErrorMessage(message);
            setShowError(true);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setShowError(false);
      };


    return (
        <Box
          sx={{
            background:
              'linear-gradient(135deg, rgba(239, 97, 145, 0.05) 0%, rgba(255, 255, 255, 0.9) 100%)',
            padding: { xs: 2, sm: 4, md: 6 },
            position: 'relative',
            minHeight: '100vh',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '300px',
              background:
                'linear-gradient(180deg, rgba(239, 97, 145, 0.08) 0%, rgba(255, 255, 255, 0) 100%)',
              zIndex: 0,
            },
          }}
        >
          <Container
            maxWidth="sm"
            sx={{
              position: 'relative',
              zIndex: 1,
              mt: { xs: 4, sm: 6, md: 8 },
              mb: { xs: 4, sm: 6, md: 8 },
            }}
          >
            <Paper
              elevation={0}
              sx={{
                borderRadius: '24px',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                backdropFilter: 'blur(20px)',
                border: '1px solid rgba(239, 97, 145, 0.1)',
                padding: { xs: 3, sm: 4, md: 6 },
                position: 'relative',
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '6px',
                  background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                  borderRadius: '24px 24px 0 0',
                },
              }}
            >
              <Box sx={{ textAlign: 'center', mb: 4 }}>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 800,
                    background: 'linear-gradient(135deg, #EF6191 0%, #FF8FB1 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    mb: 2,
                  }}
                >
                  Create an Account
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Join us and start your journey
                </Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Username"
                  name="username"
                  value={userData.username}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <PersonIcon sx={{ color: '#EF6191', mr: 1 }} />
                    ),
                  }}
                  sx={{
                    mb: 3,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#EF6191',
                      },
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label="Email"
                  type="email"
                  name="email"
                  value={userData.email}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <EmailIcon sx={{ color: '#EF6191', mr: 1 }} />
                    ),
                  }}
                  sx={{
                    mb: 3,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#EF6191',
                      },
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label="Password"
                  type="password"
                  name="password"
                  value={userData.password}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <LockIcon sx={{ color: '#EF6191', mr: 1 }} />
                    ),
                  }}
                  sx={{
                    mb: 3,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#EF6191',
                      },
                    },
                  }}
                />
                <FormControl
                  fullWidth
                  sx={{
                    mb: 3,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#EF6191',
                      },
                    },
                  }}
                >
                  <InputLabel id="user-type-label" sx={{ color: '#EF6191' }}>
                    User Type
                  </InputLabel>
                  <Select
                    labelId="user-type-label"
                    label="User Type"
                    name="user_type"
                    value={userData.user_type}
                    onChange={handleChange}
                    sx={{
                      '& .MuiSelect-icon': {
                        color: '#EF6191',
                      },
                    }}
                  >
                    <MenuItem value="vendor">Vendor</MenuItem>
                    <MenuItem value="performer">Performer</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                    color: '#fff',
                    borderRadius: '12px',
                    padding: '12px 24px',
                    fontSize: '1rem',
                    fontWeight: 600,
                    textTransform: 'none',
                    boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
                    '&:hover': {
                      background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                      boxShadow: '0 6px 16px rgba(239, 97, 145, 0.4)',
                      transform: 'translateY(-1px)',
                    },
                  }}
                >
                  Register
                </Button>
                <Box sx={{ mt: 2, textAlign: 'center' }}>
                  <Typography variant="body2">
                    <Link
                      to="/login"
                      style={{ color: '#EF6191', textDecoration: 'none' }}
                    >
                      Already Have An Account? Login.
                    </Link>
                  </Typography>
                </Box>
              </form>
            </Paper>
          </Container>
    
          {/* Error Snackbar */}
          <Snackbar
                open={showError}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="error"
                    sx={{
                        minWidth: '300px',
                        borderRadius: '16px',
                        backgroundColor: '#EF6191',
                        color: '#fff',
                        boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
                        '& .MuiAlert-icon': {
                            color: '#fff',
                        },
                        '& .MuiAlert-action': {
                            color: '#fff',
                        },
                    }}
                >
                    <Box>
                        <Typography sx={{ fontWeight: 600, mb: 0.5 }}>
                            Registration Failed!
                        </Typography>
                        <Typography variant="body2">
                            {errorMessage}
                        </Typography>
                    </Box>
                </Alert>
            </Snackbar>
        </Box>
      );
    }
    
    export default Register;
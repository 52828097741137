import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Container,
  IconButton,
  styled,
  createTheme,
  ThemeProvider,
  CircularProgress,
  Divider,
  Alert,
  Button,
} from '@mui/material';
import { useUser } from '../context/UserContext';
import { Link, useParams } from 'react-router-dom';
import { DataContext } from './DataContext';
import { CalendarToday, NotificationsNone, Message, Person, MusicNote, Payments, Stars, LocalActivity } from '@mui/icons-material';
import EnhancedVenueCard from './TopMatchedVenueCard.js';
import axios from 'axios';
import CategoryPreview from './CategoryPreview';


// Create a custom theme with an artist-focused color scheme
const theme = createTheme({
  palette: {
    primary: {
      main: '#EF6191', // A vibrant purple that's distinct from the venue pink
    },
    text: {
      primary: '#000000',
      secondary: '#666666',
    },
  },
});

// Styled components maintaining consistency with venue page
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[8],
  },
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(4),
}));

const QuickActionCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: '#f5f5f7',
  '&:hover': {
    backgroundColor: '#e8e8e8',
    cursor: 'pointer',
    '& .MuiIconButton-root': {
      transform: 'scale(1.1)',
    },
  },
  '& .MuiIconButton-root': {
    color: theme.palette.primary.main,
    transition: 'transform 0.3s ease-in-out',
  },
}));

const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: 'inherit',
  display: 'block',
});

const BrandedTypography = styled(Typography)(({ theme }) => ({
  '&.brand-heading': {
    color: theme.palette.primary.main,
  },
}));

const StatCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(108, 99, 255, 0.05)',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ArtistLandingPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  console.log('THEID', id);
  const { realUser, logout } = useUser();
  const { user, performers, venues } = useContext(DataContext);
  const [profileStatus, setProfileStatus] = useState(null);
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [profileError, setProfileError] = useState(null);
  useEffect(() => {
    const fetchProfileId = async () => {
      try {
        const response = await axios.get(`https://api.live-and-local.com/get-profile/${id}`);
        setProfileStatus(response.data.performance_type); // Adjust based on your API response structure
        setIsProfileLoading(false);
      } catch (error) {
        console.error('Error fetching profile ID:', error);
        setProfileError(error);
        setIsProfileLoading(false);
      }
    };

    if (id) {
      fetchProfileId();
    }
  }, [id]);
  
  // Artist-specific categories
  const categories = [
    { name: 'Local Venues', description: 'Find venues looking for talent', price: 'Various Capacities' },
    { name: 'Clubs', description: 'Upcoming event at Clubs', price: 'Multiple Dates' },
    { name: `Looking for ${profileStatus || 'Artist'}s`, description: `Venues Looking for ${profileStatus || 'Artist'}s`, price: 'Custom Rates' },
    { name: 'Messages', description: 'View unread messages', price: 'Contact Venues' },
  ];

  const handleCategoryClick = (categoryName) => {
    switch (categoryName.toLowerCase()) {
      case 'local venues':
        navigate('/search', {
          state: {
            initialTab: 'location',
            presetDistance: 25,
            autoSearch: true
          }
        });
        break;
      
      case 'clubs':
        navigate('/search', {
          state: {
            initialTab: 'type',
            presetVenueType: 'Club',
            autoSearch: true
          }
        });
        break;
      
      case `looking for ${profileStatus || 'artist'}s`:
        navigate('/search', {
          state: {
            initialTab: 'type',
            presetPerformanceType: profileStatus || 'Artist',
            autoSearch: true
          }
        });
        break;
      
      case 'messages':
        navigate(`/messages/${id}`);
        break;
      
      default:
        navigate('/search');
    }
  };

  const quickActions = [
    { 
      icon: <MusicNote />, 
      title: 'Manage Booking', 
      description: 'Update your performance sets',
      path: `/ArtistImpBooking/${id}`
    },
    { 
      icon: <Payments />, 
      title: 'Finances', 
      description: 'Track your payments',
      path: `/artist/${id}/earnings`
    },
    { 
      icon: <Stars />, 
      title: 'Reviews', 
      description: 'View your performance feedback',
      path: `/artist/${id}/reviews`
    },
    { 
      icon: <LocalActivity />, 
      title: 'Profile', 
      description: 'Update your calendar',
      path: `/user/profile`
    },
  ];

  const [matchedVenues, setMatchedVenues] = useState([]);
  const [recentMatches, setRecentMatches] = useState([]);
  const [recentDate, setRecentDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const matchesPerPage = 3;
  const [stats, setStats] = useState({
    totalBookings: 0,
    averageRating: 0,
    revenueThisMonth: 0,
    upcomingGigs: 0
  });

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const response = await axios.get(`https://api.live-and-local.com/api/artist-matches/${id}`);
        if (response.data) {
          setMatchedVenues(response.data.general_matches);
          setRecentMatches(response.data.recent_date_matches);
          setRecentDate(response.data.recent_date);
          setLoading(false);
        } else {
          setError('No matches found.');
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
        setError('Failed to fetch matches.');
        setLoading(false);
      }
    };

    fetchMatches();
  }, [id]);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        console.log(id);
        const response = await axios.get(`https://api.live-and-local.com/api/artist-stats/${id}`);
        setStats(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Failed to fetch artist stats", error);
      }
    };
  
    fetchStats();
  }, [id]);

  const indexOfLastMatch = currentPage * matchesPerPage;
  const indexOfFirstMatch = indexOfLastMatch - matchesPerPage;
  const currentMatches = recentMatches.slice(indexOfFirstMatch, indexOfLastMatch);
  const totalPages = Math.ceil(recentMatches.length / matchesPerPage);

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };


  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" sx={{ py: 6 }}>
        {/* Header Section */}
        <Box sx={{ mb: 8 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <BrandedTypography variant="h3" component="h1" className="brand-heading" sx={{ fontWeight: 600 }}>
                Your Stage Awaits.
              </BrandedTypography>
              <Typography variant="h3" sx={{ color: 'text.secondary', fontWeight: 600, mt: 2 }}>
                Connect with venues that match your style.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              {matchedVenues.length > 0 && (
                <EnhancedVenueCard 
                  venue={matchedVenues[0]} 
                  isFeatureCard={true}
                />
              )}
            </Grid>
          </Grid>
        </Box>
        

        {/* Stats Section - New addition for artists */}
        <Box sx={{ mb: 8 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard>
                <Typography variant="h4" color="primary" sx={{ fontWeight: 600 }}>
                  {stats.upcomingGigs}
                </Typography>
                <Typography variant="subtitle1">Upcoming Gigs</Typography>
              </StatCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard>
                <Typography variant="h4" color="primary" sx={{ fontWeight: 600 }}>
                  {stats.averageRating.toFixed(1)}
                </Typography>
                <Typography variant="subtitle1">Average Rating</Typography>
              </StatCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard>
                <Typography variant="h4" color="primary" sx={{ fontWeight: 600 }}>
                  ${stats.revenueThisMonth}
                </Typography>
                <Typography variant="subtitle1">This Month</Typography>
              </StatCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard>
                <Typography variant="h4" color="primary" sx={{ fontWeight: 600 }}>
                  {stats.totalBookings}
                </Typography>
                <Typography variant="subtitle1">Pending Bids</Typography>
              </StatCard>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mb: 8 }}>
          <Grid container spacing={3}>
            {categories.map((category, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <div onClick={() => handleCategoryClick(category.name)}>
                  <StyledCard>
                    <CardContent>
                      <BrandedTypography variant="h6" className="brand-heading" sx={{ mb: 1 }}>
                        {category.name}
                      </BrandedTypography>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        {category.description}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
                        {category.price}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
        



        {/* General Matches Section */}
        <Box sx={{ mb: 8 }}>
          <Typography variant="h4" sx={{ mb: 2, color: 'primary.main' }}>
            Top Venue Matches
          </Typography>

          {!loading && !error && matchedVenues.length > 0 && (
            <Grid container spacing={4}>
              {matchedVenues.slice(0, 3).map((venue) => (
                <Grid item xs={12} sm={6} md={4} key={venue.id}>
                  <EnhancedVenueCard venue={venue} />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>

        {/* Quick Actions Section */}
        <Box sx={{ mb: 8 }}>
          <BrandedTypography variant="h4" className="brand-heading" sx={{ mb: 1 }}>
            Artist Tools
          </BrandedTypography>
          <Typography variant="h5" sx={{ color: 'text.secondary', mb: 4 }}>
            Everything you need to manage your performances.
          </Typography>

          <Grid container spacing={3}>
            {quickActions.map((action, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <StyledLink to={action.path}>
                  <QuickActionCard>
                    <CardContent>
                      <IconButton sx={{ mb: 2, p: 0 }}>
                        {action.icon}
                      </IconButton>
                      <Typography variant="h6" sx={{ mb: 1 }}>
                        {action.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {action.description}
                      </Typography>
                    </CardContent>
                  </QuickActionCard>
                </StyledLink>
              </Grid>
            ))}
          </Grid>
        </Box>
        <CategoryPreview title="Available Venues" items={venues} />
      </Container>
    </ThemeProvider>
  );
};

export default ArtistLandingPage;
import React, { useState, useEffect  } from 'react';
import { 
  Box, Typography, TextField, Select, MenuItem, Button, 
  FormControl, InputLabel, Chip, Checkbox,Container, ListItemText, OutlinedInput, Paper, Snackbar, Alert
} from '@mui/material';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { CalendarToday, AccessTime, LocationOn, Group, MusicNote, WorkspacePremium } from '@mui/icons-material';
import './ImpVenueBookingForm.css';

const VenueBookingForm = ({ userId: propUserId, setCurrentPage }) => {
  const navigate = useNavigate();
  const { id } = useParams(); 
  const { user_id } = useParams();
  const [userId, setUserId] = useState(propUserId);

  const [venueProfile, setVenueProfile] = useState({
    setting: [],
    capacity: '',
    location: '',
    stageTypes: [],
  });

  const [booking, setBooking] = useState({
    setting: '',
    date: null,
    startTime: null,
    endTime: null,
    performanceType: '',
    experienceLevel: '',
    genres: [],
    priceRange: { min: '', max: '' },
  });

  const handleGenreChange = (event) => {
    const { value } = event.target;
    setBooking(prev => ({
      ...prev,
      genres: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const listGenres = [
    'Rock', 'Pop', 'Jazz', 'Blues', 'Hip-Hop', 'Country', 
    'EDM (Electronic Dance Music)', 'Folk', 'Reggae', 'Classical',
    'Indie', 'R&B/Soul', 'Metal', 'World Music', 'Other'
  ];
  
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const settingOptions = [
    'Bar', 'Nightclub', 'Café', 'Restaurant', 'Lounge', 
    'Concert Hall', 'Outdoor Space', 'Brewery/Tavern', 
    'Private Event Space', 'Other'
  ];

  useEffect(() => {
    if (!userId && id) {
      axios.get(`https://api.live-and-local.com/get-profile/${id}`)
        .then(response => {
          if (response.data && response.data.profile_id) {
            setUserId(response.data.profile_id);
          }
        })
        .catch(error => {
          console.error('Error getting profile:', error);
        });
    }
  }, [userId, id]);

  useEffect(() => {
    if (userId) {
      axios.get(`https://api.live-and-local.com/create-date/${userId}`)
        .then(response => {
          const data = response.data;
          
          // Parse the settings properly
          let settingArray = [];
          if (Array.isArray(data.setting)) {
            settingArray = data.setting;
          } else if (typeof data.setting === 'string') {
            try {
              settingArray = JSON.parse(data.setting);
            } catch {
              settingArray = [data.setting];
            }
          }
          
          setVenueProfile({
            setting: settingArray,
            capacity: data.capacity || '',
            location: data.location || '',
            stageTypes: Array.isArray(data.stageTypes) ? data.stageTypes : [],
          });

          // Set the initial booking settings
          setBooking(prev => ({
            ...prev,
            setting: settingArray
          }));
        })
        .catch(error => {
          console.error('Error fetching profile data:', error);
        });
    }
  }, [userId]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'setting') {
      // For settings, keep it as an array
      setBooking(prev => ({
        ...prev,
        setting: Array.isArray(value) ? value : [value]
      }));
    } else {
      setBooking(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleDateChange = (newValue) => {
    setBooking(prev => ({ ...prev, date: newValue }));
  };

  const handleTimeChange = (newValue, field) => {
    setBooking(prev => ({ ...prev, [field]: newValue }));
  };

  const handlePriceRangeChange = (event) => {
    const { name, value } = event.target;
    setBooking(prev => ({
      ...prev,
      priceRange: {
        ...prev.priceRange,
        [name]: value,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    // Format the date and times for the API
    const formatDate = (date) => {
      return date ? date.toISOString().split('T')[0] : null;
    };

    const formatTime = (time) => {
      return time ? time.toTimeString().slice(0, 5) : null;
    };

    const bookingData = {
      ...booking,
      date: formatDate(booking.date),
      startTime: formatTime(booking.startTime),
      endTime: formatTime(booking.endTime),
      priceRange: {
        min: booking.priceRange.min || null,
        max: booking.priceRange.max || null,
      },
      venueProfile: {
        setting: venueProfile.setting,
        capacity: venueProfile.capacity,
        location: venueProfile.location,
      },
    };
  
    fetch(`https://api.live-and-local.com/create-date/${userId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bookingData),
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to save booking');
        }
        return response.json();
      })
      .then((data) => {
        setOpenSnackbar(true);
        setTimeout(() => {
          setCurrentPage('landing')
        }, 2000);
      })
      .catch((error) => {
        console.error('Error saving booking:', error);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box 
        className="min-h-screen"
        sx={{
          background: 'linear-gradient(135deg, rgba(239, 97, 145, 0.05) 0%, rgba(255, 255, 255, 0.9) 100%)',
          padding: { xs: 2, sm: 4, md: 6 },
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '300px',
            background: 'linear-gradient(180deg, rgba(239, 97, 145, 0.08) 0%, rgba(255, 255, 255, 0) 100%)',
            zIndex: 0,
          }
        }}
      >
        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
          {/* Header Section */}
          <Box sx={{ textAlign: 'center', mb: { xs: 4, md: 8 } }}>
            <Typography 
              variant="h2" 
              sx={{
                fontSize: { xs: '2.5rem', md: '3.5rem' },
                fontWeight: 800,
                background: 'linear-gradient(135deg, #EF6191 0%, #FF8FB1 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                mb: 2,
                letterSpacing: '-0.02em'
              }}
            >
              Create Your Booking
            </Typography>
            <Typography 
              variant="h6" 
              sx={{ 
                color: 'text.secondary',
                maxWidth: '600px',
                margin: '0 auto',
                fontSize: '1.1rem',
                lineHeight: 1.6
              }}
            >
              Share your venue's details and connect with incredible performers
            </Typography>
          </Box>

          {/* Main Form */}
          <Paper 
            elevation={0}
            sx={{
              maxWidth: '900px',
              margin: '0 auto',
              borderRadius: '24px',
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              backdropFilter: 'blur(20px)',
              border: '1px solid rgba(239, 97, 145, 0.1)',
              padding: { xs: 3, sm: 4, md: 6 },
              overflow: 'hidden',
              position: 'relative',
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '6px',
                background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
              }
            }}
          >
            <Box sx={{ 
                p: 3, 
                borderRadius: '16px', 
                backgroundColor: 'rgba(239, 97, 145, 0.05)',
                border: '1px solid rgba(239, 97, 145, 0.1)',
                marginBottom: '5%'
              }}>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: '#EF6191' }}>
                  Venue Information
                </Typography>
                <Box sx={{ display: 'grid', gap: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <LocationOn sx={{ color: '#EF6191' }} />
                    <Typography>
                      {venueProfile.location || 'Location not specified'}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Group sx={{ color: '#EF6191' }} />
                    <Typography>
                      Capacity: {venueProfile.capacity || 'Not specified'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            <Box component="form" onSubmit={handleSubmit} sx={{ position: 'relative' }}>
              {/* Venue Type Section */}
              <Box sx={{ mb: 6 }}>
                <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
                  Venue Details
                </Typography>
                <FormControl fullWidth>
                  <InputLabel sx={{ 
                    '&.Mui-focused': { color: '#EF6191' }
                  }}>
                    Setting
                  </InputLabel>
                  <Select
                    multiple
                    name="setting"
                    value={Array.isArray(booking.setting) ? booking.setting : []}
                    onChange={handleChange}
                    label="Setting"
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.1)',
                        borderWidth: '1.5px',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#EF6191',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#EF6191',
                        borderWidth: '2px',
                      },
                      borderRadius: '16px',
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {selected.map((value) => (
                          <Chip 
                            key={value} 
                            label={value} 
                            sx={{
                              backgroundColor: '#EF6191',
                              color: '#fff',
                              borderRadius: '12px',
                              fontWeight: 600,
                              padding: '4px 2px',
                              '& .MuiChip-label': {
                                padding: '0 12px',
                              }
                            }} 
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {settingOptions.map((setting) => (
                      <MenuItem key={setting} value={setting}>
                        <Checkbox 
                          checked={booking.setting.indexOf(setting) > -1}
                          sx={{
                            color: '#EF6191',
                            '&.Mui-checked': {
                              color: '#EF6191',
                            },
                          }}
                        />
                        <ListItemText primary={setting} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {/* Date and Time Section */}
              <Box sx={{ mb: 6 }}>
                <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
                  Date & Time
                </Typography>
                <Box sx={{ 
                  display: 'grid', 
                  gap: 3,
                  gridTemplateColumns: { xs: '1fr', md: '1fr 2fr' }
                }}>
                  <Box>
                    <DatePicker
                      label="Event Date"
                      value={booking.date}
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <TextField 
                          {...params} 
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '16px',
                              backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            }
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                    <TimePicker
                      label="Start Time"
                      value={booking.startTime}
                      onChange={(newValue) => handleTimeChange(newValue, 'startTime')}
                      renderInput={(params) => (
                        <TextField 
                          {...params} 
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '16px',
                              backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            }
                          }}
                        />
                      )}
                    />
                    <TimePicker
                      label="End Time"
                      value={booking.endTime}
                      onChange={(newValue) => handleTimeChange(newValue, 'endTime')}
                      renderInput={(params) => (
                        <TextField 
                          {...params} 
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '16px',
                              backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            }
                          }}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Box>

              {/* Performance Requirements */}
              <Box sx={{ mb: 6 }}>
                <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
                  Performance Requirements
                </Typography>
                <Box sx={{ display: 'grid', gap: 3, gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' } }}>
                  <FormControl fullWidth>
                    <InputLabel>Performance Type</InputLabel>
                    <Select
                      name="performanceType"
                      value={booking.performanceType}
                      onChange={handleChange}
                      label="Performance Type"
                      sx={{
                        borderRadius: '16px',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#EF6191',
                        },
                      }}
                    >
                      <MenuItem value="solo">Solo Artist</MenuItem>
                      <MenuItem value="band">Band</MenuItem>
                      <MenuItem value="dj">DJ</MenuItem>
                      <MenuItem value="duo">Duo</MenuItem>
                      <MenuItem value="ensemble">Ensemble</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel>Experience Level</InputLabel>
                    <Select
                      name="experienceLevel"
                      value={booking.experienceLevel}
                      onChange={handleChange}
                      label="Experience Level"
                      sx={{
                        borderRadius: '16px',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#EF6191',
                        },
                      }}
                    >
                      <MenuItem value="beginner">Beginner (1-3 years)</MenuItem>
                      <MenuItem value="intermediate">Intermediate (3-5 years)</MenuItem>
                      <MenuItem value="experienced">Experienced (5-10 years)</MenuItem>
                      <MenuItem value="professional">Professional (10+ years)</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              {/* Genres Section */}
              <Box sx={{ mb: 6 }}>
                <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
                  Music Genres
                </Typography>
                <FormControl fullWidth>
                  <InputLabel>Select Genres</InputLabel>
                  <Select
                    multiple
                    name="genres"
                    value={booking.genres}
                    onChange={handleGenreChange}
                    input={<OutlinedInput label="Select Genres" />}
                    sx={{
                      borderRadius: '16px',
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#EF6191',
                      },
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {selected.map((value) => (
                          <Chip 
                            key={value} 
                            label={value}
                            sx={{
                              backgroundColor: '#EF6191',
                              color: '#fff',
                              borderRadius: '12px',
                              fontWeight: 600,
                              '& .MuiChip-label': {
                                padding: '0 12px',
                              }
                            }}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {listGenres.map((genre) => (
                      <MenuItem key={genre} value={genre}>
                        <Checkbox 
                          checked={booking.genres.indexOf(genre) > -1}
                          sx={{
                            color: '#EF6191',
                            '&.Mui-checked': {
                              color: '#EF6191',
                            },
                          }}
                        />
                        <ListItemText primary={genre} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {/* Price Range */}
              <Box sx={{ mb: 6 }}>
                <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
                  Budget Range
                </Typography>
                <Box sx={{ display: 'grid', gap: 3, gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' } }}>
                  <TextField
                    label="Minimum Budget"
                    name="min"
                    type="number"
                    value={booking.priceRange.min}
                    onChange={handlePriceRangeChange}
                    InputProps={{ 
                      startAdornment: '$',
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '16px',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      },
                    }}
                  />
                  <TextField
                    label="Maximum Budget"
                    name="max"
                    type="number"
                    value={booking.priceRange.max}
                    onChange={handlePriceRangeChange}
                    InputProps={{ 
                      startAdornment: '$',
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '16px',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      },
                    }}
                  />
                </Box>
              </Box>

              <Button 
                type="submit" 
                variant="contained" 
                fullWidth
                sx={{
                  background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                  color: '#fff',
                  borderRadius: '16px',
                  height: '56px',
                  fontSize: '1.1rem',
                  fontWeight: 600,
                  textTransform: 'none',
                  boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
                  '&:hover': {
                    background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                    boxShadow: '0 6px 16px rgba(239, 97, 145, 0.4)',
                    transform: 'translateY(-1px)',
                  },
                  '&:active': {
                    transform: 'translateY(1px)',
                    boxShadow: '0 2px 8px rgba(239, 97, 145, 0.4)',
                  },
                  transition: 'all 0.2s ease-in-out',
                }}
              >
                Create Booking
              </Button>

              
            </Box>
          </Paper>

          {/* Success Message */}
          <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert 
              onClose={handleCloseSnackbar} 
              severity="success" 
              sx={{ 
                minWidth: '300px',
                borderRadius: '16px',
                backgroundColor: '#EF6191',
                color: '#fff',
                boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
                '& .MuiAlert-icon': {
                  color: '#fff'
                },
                '& .MuiAlert-action': {
                  color: '#fff'
                }
              }}
              icon={<MusicNote sx={{ fontSize: '24px' }} />}
            >
              <Box>
                <Typography sx={{ fontWeight: 600, mb: 0.5 }}>
                  Booking Created!
                </Typography>
                <Typography variant="body2">
                  Your booking has been successfully posted
                </Typography>
              </Box>
            </Alert>
          </Snackbar>

          {/* Optional Footer Message */}
          <Box sx={{ 
            textAlign: 'center', 
            mt: 4, 
            color: 'text.secondary',
            opacity: 0.8
          }}>
            <Typography variant="body2">
              Need help? Contact our support team
            </Typography>
          </Box>
        </Container>
      </Box>
    </LocalizationProvider>
  );
};

export default VenueBookingForm;
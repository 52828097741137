import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
const HeaderContainer = styled.header`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  padding: 1rem 2rem;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Poppins', sans-serif;

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: 
      radial-gradient(circle at 20% 30%, rgba(239, 97, 145, 0.15) 0%, transparent 50%),
      radial-gradient(circle at 80% 70%, rgba(41, 98, 255, 0.15) 0%, transparent 50%),
      radial-gradient(circle at center, rgba(255, 255, 255, 0.2), transparent 70%);
    animation: rotateBG 30s linear infinite;
    z-index: 0;
  }

  @keyframes rotateBG {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 4fr 2fr; // Adjusted to give more space to center column
  align-items: center;
`;

const TitleGroup = styled.div`
  text-align: left;
  padding-left: 0;
`;


const Title = styled(motion.h1)`
  font-size: 3.5rem;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  text-align: left;
  margin: 0;
  padding-left: 0;
  position: relative;
  background: linear-gradient(135deg, #EF6191 0%, #2962ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    filter: blur(20px);
    opacity: 0.3;
    z-index: -1;
  }
`;

const Subtitle = styled(motion.h2)`
  font-size: 1.5rem;
  color: rgba(28, 32, 40, 0.8);
  margin: 0.5rem 0 0;
  padding-left: 0;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  text-align: left;
`;

const StatsWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StatsBadge = styled.div`
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 12px;
  padding: 1rem 2rem;
  display: flex;
  gap: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const StatItem = styled.div`
  text-align: center;
  font-family: 'Poppins', sans-serif;

  h3 {
    color: #EF6191;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 700;
  }

  p {
    color: rgba(28, 32, 40, 0.7);
    margin: 0.25rem 0 0;
    font-size: 0.875rem;
    font-weight: 300;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CTAButton = styled(motion.button)`
  background: linear-gradient(90deg, #EF6191, #2962ff);
  border: none;
  border-radius: 12px;
  color: white;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(239, 97, 145, 0.2);
  white-space: nowrap;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: 0.5s;
  }

  &:hover::before {
    left: 100%;
  }

  &:hover {
    box-shadow: 0 8px 20px rgba(239, 97, 145, 0.3),
                0 0 0 1px rgba(255, 255, 255, 0.2),
                0 0 20px rgba(239, 97, 145, 0.2);
  }
`;

const HomeHeader = () => {
  const [stats, setStats] = useState({
      total_events: 0,
      booked_events: 0
    });

    const navigate = useNavigate();
    useEffect(() => {
      const fetchStats = async () => {
        try {
          const response = await fetch('https://api.live-and-local.com/api/booking/stats');
          if (!response.ok) {
            throw new Error('Failed to fetch stats');
          }
          const data = await response.json();
          setStats(data);
        } catch (error) {
          console.error('Error fetching stats:', error);
        }
      };
  
      fetchStats();
      
      const interval = setInterval(fetchStats, 600000); 
      
      return () => clearInterval(interval);
    }, []);

    const navigateToLogin = () => {
      navigate('/login');
    };


  return (
    <HeaderContainer>
      <ContentWrapper>
        <TitleGroup>
          <Title
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <span>Welcome to</span>
            <br />
            <span>Live & Local!</span>
          </Title>
          <Subtitle
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            Discover Upcoming Events
          </Subtitle>
        </TitleGroup>

        <StatsWrapper
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <StatsBadge>
            <StatItem>
            <h3>{stats.total_events}</h3>
              <p>Event Postings</p>
            </StatItem>
            <StatItem>
              <h3>{stats.booked_events}</h3>
              <p>Booked Events</p>
            </StatItem>
          </StatsBadge>
        </StatsWrapper>

        <ButtonWrapper>
          <CTAButton
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
            onClick={navigateToLogin}
          >
            Login or Sign Up to Join
          </CTAButton>
        </ButtonWrapper>
      </ContentWrapper>
    </HeaderContainer>
  );
};

export default HomeHeader;
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext'; // Make sure the path is correct
import './AuthStyles.css'; // Ensuring your custom styles are applied
import ClearDBButton from './killbutton';
import { Link } from 'react-router-dom';
import {
    Box,
    Typography,
    TextField,
    Button,
    Container,
    Paper,
    Snackbar,
    Alert,
    IconButton,
  } from '@mui/material';
  import {
    Close as CloseIcon,
    Lock as LockIcon,
    Person as PersonIcon,
  } from '@mui/icons-material';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const { login } = useUser(); // Use the login function from the context

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://api.live-and-local.com/login', {
                username: username,
                password: password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });

            if (response.data.user_id) {
                login(response.data); // Update the global user state on successful login
                console.log(response.data);
                console.log('-------------------------------------------------');
                const userType = response.data.user_type; // Assuming user_type is in response
                if (userType === 'vendor') {
                    navigate(`/VenueLandingPage/${response.data.user_id}`);
                } else if (userType === 'performer') {
                    navigate(`/ArtistLandingPage/${response.data.user_id}`);
                } else {
                    navigate('/'); // Default to home if user type is not specified
                }
            } else {
                alert('Login failed: ' + response.data.error);
            }
        } catch (error) {
            console.error('Login Failed:', error.response ? error.response.data : "No response from server");
            alert('Invalid credentials'); // Provide user feedback
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setShowError(false);
      };

      return (
        <Box
          sx={{
            background:
              'linear-gradient(135deg, rgba(239, 97, 145, 0.05) 0%, rgba(255, 255, 255, 0.9) 100%)',
            padding: { xs: 2, sm: 4, md: 6 },
            position: 'relative',
            minHeight: '100vh',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '300px',
              background:
                'linear-gradient(180deg, rgba(239, 97, 145, 0.08) 0%, rgba(255, 255, 255, 0) 100%)',
              zIndex: 0,
            },
          }}
        >
          <Container
            maxWidth="sm"
            sx={{ position: 'relative', zIndex: 1, mt: { xs: 6, sm: 8, md: 10 } }}
          >
            <Paper
              elevation={0}
              sx={{
                borderRadius: '24px',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                backdropFilter: 'blur(20px)',
                border: '1px solid rgba(239, 97, 145, 0.1)',
                padding: { xs: 3, sm: 4, md: 6 },
                mb: 4,
                position: 'relative',
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '6px',
                  background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                  borderRadius: '24px 24px 0 0',
                },
              }}
            >
              <Box sx={{ textAlign: 'center', mb: 4 }}>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 800,
                    background:
                      'linear-gradient(135deg, #EF6191 0%, #FF8FB1 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    mb: 2,
                  }}
                >
                  Welcome Back
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Please login to your account
                </Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <PersonIcon sx={{ color: '#EF6191', mr: 1 }} />
                    ),
                  }}
                  sx={{
                    mb: 3,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#EF6191',
                      },
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <LockIcon sx={{ color: '#EF6191', mr: 1 }} />
                    ),
                  }}
                  sx={{
                    mb: 3,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#EF6191',
                      },
                    },
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                    color: '#fff',
                    borderRadius: '12px',
                    padding: '12px 24px',
                    fontSize: '1rem',
                    fontWeight: 600,
                    textTransform: 'none',
                    boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
                    '&:hover': {
                      background:
                        'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                      boxShadow: '0 6px 16px rgba(239, 97, 145, 0.4)',
                      transform: 'translateY(-1px)',
                    },
                  }}
                >
                  Login
                </Button>
                <Box sx={{ mt: 2, textAlign: 'center' }}>
                  <Typography variant="body2">
                    <Link
                      to="/forgot-password"
                      style={{ color: '#EF6191', textDecoration: 'none' }}
                    >
                      Forgot Password?
                    </Link>
                  </Typography>
                </Box>
                <Box sx={{ mt: 2, textAlign: 'center' }}>
                  <Typography variant="body2">
                    <Link
                      to="/register"
                      style={{ color: '#EF6191', textDecoration: 'none' }}
                    >
                      New to BookUp? Sign Up!
                    </Link>
                  </Typography>
                </Box>
              </form>
            </Paper>
          </Container>
    
          {/* Error Snackbar */}
          <Snackbar
            open={showError}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity="error"
              sx={{
                minWidth: '300px',
                borderRadius: '16px',
                backgroundColor: '#EF6191',
                color: '#fff',
                boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
                '& .MuiAlert-icon': {
                  color: '#fff',
                },
                '& .MuiAlert-action': {
                  color: '#fff',
                },
              }}
              icon={<CloseIcon sx={{ fontSize: '24px' }} />}
            >
              <Box>
                <Typography sx={{ fontWeight: 600, mb: 0.5 }}>
                  Login Failed!
                </Typography>
                <Typography variant="body2">
                  Invalid username or password.
                </Typography>
              </Box>
            </Alert>
          </Snackbar>
        </Box>
      );
    }
    
    export default Login;
import React, { useState, useEffect } from 'react';
import VendorMap from './Map';  // Import your existing VendorMap component
import marker from '../MapLogo.svg';
import L from 'leaflet';
import './Address.css';
import { Dialog, DialogTitle, DialogContent, TextField, Button, Box, IconButton } from '@mui/material';

const AddressConfirmationModal = ({ isOpen, onClose, onConfirm, initialAddress }) => {
    const [addressFields, setAddressFields] = useState({
      street: '',
      city: '',
      state: '',
      postalCode: ''
    });
    const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [mapKey, setMapKey] = useState(0);
  
    const fetchCoordinates = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch('https://api.live-and-local.com/api/confirm_address', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(addressFields),
        });
        const data = await response.json();
        if (data.success) {
          setCoordinates({ lat: data.lat, lng: data.lng });
          setMapKey(prevKey => prevKey + 1);
        } else {
          setError(data.message || 'Unable to find coordinates for the given address.');
        }
      } catch (err) {
        setError('An error occurred while fetching coordinates.');
      } finally {
        setIsLoading(false);
      }
    };
  
    const handleConfirm = () => {
        const fullAddress = `${addressFields.street}, ${addressFields.city}, ${addressFields.state} ${addressFields.postalCode}`;
        onConfirm(fullAddress, coordinates);
        onClose();
    };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setAddressFields(prev => ({ ...prev, [name]: value }));
    };
  
    useEffect(() => {
      if (isOpen && initialAddress) {
        const [street, city, stateZip] = initialAddress.split(', ');
        const [state, postalCode] = stateZip ? stateZip.split(' ') : ['', ''];
        setAddressFields({ street, city, state, postalCode });
      }
    }, [isOpen, initialAddress]);
  
    if (!isOpen) return null;
  
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Confirm Address</h2>
            <button className="modal-close" onClick={onClose}>&times;</button>
          </div>
          <div className="address-fields">
            <input
              type="text"
              name="street"
              value={addressFields.street}
              onChange={handleInputChange}
              placeholder="Street Address"
            />
            <input
              type="text"
              name="city"
              value={addressFields.city}
              onChange={handleInputChange}
              placeholder="City"
            />
            <input
              type="text"
              name="state"
              value={addressFields.state}
              onChange={handleInputChange}
              placeholder="State"
            />
            <input
              type="text"
              name="postalCode"
              value={addressFields.postalCode}
              onChange={handleInputChange}
              placeholder="Postal Code"
            />
          </div>

          <Button
              variant="contained"
              onClick={() => {fetchCoordinates()}}
              sx={{
                flex: 1,
                background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                color: '#fff',
                borderRadius: '12px',
                padding: '12px',
                textTransform: 'none',
                fontWeight: 600,
                '&:hover': {
                  background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                  boxShadow: '0 6px 16px rgba(239, 97, 145, 0.4)',
                  transform: 'translateY(-1px)',
                },
              }}
            >
              Find on Map
            </Button>
          {error && <p className="error">{error}</p>}
          {coordinates.lat !== 0 && coordinates.lng !== 0 && (
            <div className="map-container">
              <VendorMap
                key={mapKey}
                center={[coordinates.lat, coordinates.lng]}
                zoom={15}
                scrollWheelZoom={false}
                style={{ height: "100%", width: "100%" }}
                customVenues={[{
                  id: 'temp',
                  latitude: coordinates.lat,
                  longitude: coordinates.lng,
                  name: 'Your Location',
                  address: `${addressFields.street}, ${addressFields.city}, ${addressFields.state} ${addressFields.postalCode}`
                }]}
              />
            </div>
          )}
          <Button
              variant="contained"
              onClick={() => {handleConfirm()}}
              sx={{
                flex: 1,
                background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                color: '#fff',
                borderRadius: '12px',
                padding: '12px',
                textTransform: 'none',
                fontWeight: 600,
                '&:hover': {
                  background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                  boxShadow: '0 6px 16px rgba(239, 97, 145, 0.4)',
                  transform: 'translateY(-1px)',
                },
              }}
            >
              Confirm Location
            </Button>

            <Button
              variant="outlined"
              onClick={onClose}
              sx={{
                flex: 1,
                borderColor: '#EF6191',
                color: '#EF6191',
                borderRadius: '12px',
                padding: '12px',
                textTransform: 'none',
                fontWeight: 600,
                '&:hover': {
                  borderColor: '#FF8FB1',
                  backgroundColor: 'rgba(239, 97, 145, 0.05)',
                },
              }}
            >
              Cancel
            </Button>
        </div>
      </div>
    );
  };
  
  export default AddressConfirmationModal;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ArtistBookingDiscover.css';
import { 
    Box,
    Paper,
    Typography,
    Button,
    IconButton,
    Grid,
    Tab,
    Tabs,
    TextField,
    Select,
    MenuItem,
    Avatar,
    Fade,
    InputAdornment
  } from '@mui/material';
  import {
    Search,
    MapPin,
    Calendar,
    Clock,
    Music,
    ChevronDown,
    LayoutGrid
  } from 'lucide-react';
import ArtistBookingBidPopup from './ArtistBookingBidPopup';

const StyledFeed = () => {
    const routerLocation = useLocation();
    const initialState = routerLocation.state || {};
    
    const [userLocation, setUserLocation] = useState(null);
    const [cityState, setCityState] = useState('');
    const [musicType, setMusicType] = useState('');
    const [feedItems, setFeedItems] = useState([]);
    const [distance, setDistance] = useState(initialState.presetDistance || 50);
    const [searchDate, setSearchDate] = useState(new Date());
    const [currentTab, setCurrentTab] = useState(initialState.initialTab || 'location');
    const [performanceType, setPerformanceType] = useState(initialState.presetPerformanceType || '');
    const [venueType, setVenueType] = useState(initialState.presetVenueType || '');
    const [experienceLevel, setExperienceLevel] = useState('');
    const [capacity, setCapacity] = useState('');
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [showContractPopup, setShowContractPopup] = useState(false);

    const genres = [
        'Rock', 'Pop', 'Jazz', 'Blues', 'Hip-Hop', 'Country', 
        'EDM (Electronic Dance Music)', 'Folk', 'Reggae', 'Classical', 
        'Indie', 'R&B/Soul', 'Metal', 'World Music', 'Other'
    ];

    const performanceTypes = [
        'Solo Act', 'Duo', 'Band', 'DJ', 'Ensemble'
    ];

    const distances = {
        5: "5 Miles",
        10: "10 Miles",
        25: "25 Miles",
        50: "50 Miles",
        100: "100 Miles"
    };

    const resetStateVariables = (currentTab) => {
        if (currentTab !== 'location') {
            setUserLocation(null);
            setCityState('');
            setDistance(50);
        }
        if (currentTab !== 'date') {
            setSearchDate(new Date());
        }
        if (currentTab !== 'genre') {
            setMusicType('');
        }
        if (currentTab !== 'type') {
            setPerformanceType('');
        }
    };

    const handleBidClick = (item) => {
        setSelectedBooking(item);
        setShowContractPopup(true);
    };

    const handleCloseContractPopup = () => {
        setShowContractPopup(false);
    };

    const handleUseLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                const { latitude, longitude } = position.coords;
                setUserLocation({ latitude, longitude });
            });
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };

    const handleSearch = async () => {
        const searchData = { searchType: currentTab };
        
        if (currentTab === 'location') {
            searchData.location = userLocation;
            searchData.cityState = cityState;
            searchData.distance = distance;
        } else if (currentTab === 'date') {
            searchData.searchDate = searchDate.toISOString();
        } else if (currentTab === 'genre') {
            searchData.musicType = musicType;
        } else if (currentTab === 'type') {
            searchData.performanceType = performanceType;
            searchData.venueType = venueType;
        }
    
        try {
            const response = await axios.post(
                'https://api.live-and-local.com/LocalAvailableBookings', 
                searchData, 
                { withCredentials: true }
            );
            setFeedItems(response.data);
        } catch (error) {
            console.error('Error fetching feed data:', error);
        }
    };

    useEffect(() => {
        if (initialState.autoSearch) {
            handleSearch();
        }
    }, []);

    const EventCard = ({ item }) => (
        <Fade in timeout={500}>
          <Paper
            elevation={0}
            sx={{
              mb: 3,
              borderRadius: 2,
              border: '1px solid rgba(0, 0, 0, 0.08)',
              overflow: 'hidden',
              transition: 'all 0.2s ease',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 12px 24px -10px rgba(146, 74, 174, 0.2)',
                borderColor: 'rgba(146, 74, 174, 0.3)',
              }
            }}
          >
            <Box sx={{ p: 3 }}>
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <Avatar
                    src={item.artist_url}
                    sx={{ 
                      width: 80, 
                      height: 80,
                      border: '3px solid rgba(146, 74, 174, 0.1)'
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 600,
                      color: '#EF6191',
                      mb: 1
                    }}
                  >
                    {item.venue_name}
                  </Typography>
                  
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Calendar size={20} className="text-purple-500 mr-2" />
                        <Typography variant="body2" color="text.secondary">
                          {new Date(item.date).toLocaleDateString()}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Clock size={20} className="text-purple-500 mr-2" />
                        <Typography variant="body2" color="text.secondary">
                          {item.start_time} - {item.end_time}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <MapPin size={20} className="text-purple-500 mr-2" />
                        <Typography variant="body2" color="text.secondary">
                          {item.location}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Music size={20} className="text-purple-500 mr-2" />
                        <Typography variant="body2" color="text.secondary">
                          {item.genre}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
    
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                alignItems: 'center',
                mt: 3,
                pt: 3,
                borderTop: '1px solid rgba(0, 0, 0, 0.06)'
              }}>
                <Typography variant="h6" sx={{ color: '#EF6191', fontWeight: 500 }}>
                  ${item.min_cost}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => handleBidClick(item)}
                  sx={{
                    bgcolor: '#EF6191',
                    '&:hover': {
                      bgcolor: '#7a3b91'
                    }
                  }}
                >
                  View Event
                </Button>
              </Box>
            </Box>
          </Paper>
        </Fade>
      );
    
      return (
        <Box sx={{ 
          maxWidth: 1200, 
          mx: 'auto', 
          p: { xs: 2, sm: 3, md: 4 },
          bgcolor: '#ffffff'
        }}>
          <Paper
            elevation={0}
            sx={{
              mb: 4,
              borderRadius: 2,
              border: '1px solid rgba(0, 0, 0, 0.08)',
              overflow: 'hidden'
            }}
          >
            <Tabs
              value={currentTab}
              onChange={(_, newValue) => setCurrentTab(newValue)}
              sx={{
                borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
                '& .MuiTab-root': {
                  minWidth: 120,
                  color: 'text.secondary',
                  '&.Mui-selected': {
                    color: '#EF6191'
                  }
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: '#EF6191'
                }
              }}
            >
              <Tab icon={<MapPin size={20} />} label="Location" value="location" />
              <Tab icon={<Calendar size={20} />} label="Date" value="date" />
              <Tab icon={<Music size={20} />} label="Genre" value="genre" />
              <Tab icon={<LayoutGrid size={20} />} label="Type" value="type" />
            </Tabs>
    
            <Box sx={{ p: 3 }}>
              <Grid container spacing={3} alignItems="center">
                {currentTab === 'location' && (
                  <>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        placeholder="Enter City, State"
                        value={cityState}
                        onChange={(e) => setCityState(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MapPin size={20} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Select
                        fullWidth
                        value={distance}
                        onChange={(e) => setDistance(e.target.value)}
                        IconComponent={() => <ChevronDown size={20} />}
                      >
                        {Object.entries(distances).map(([value, label]) => (
                          <MenuItem key={value} value={Number(value)}>{label}</MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </>
                )}
    
                {currentTab === 'date' && (
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      selected={searchDate}
                      onChange={(date) => setSearchDate(date)}
                    />
                  </Grid>
                )}
    
                {currentTab === 'genre' && (
                  <Grid item xs={12} md={6}>
                    <Select
                      fullWidth
                      value={musicType}
                      onChange={(e) => setMusicType(e.target.value)}
                      displayEmpty
                      IconComponent={() => <ChevronDown size={20} />}
                    >
                      <MenuItem value="">Select Genre</MenuItem>
                      {genres.map((genre) => (
                        <MenuItem key={genre} value={genre}>{genre}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}
    
                {currentTab === 'type' && (
                  <Grid item xs={12} md={6}>
                    <Select
                      fullWidth
                      value={performanceType}
                      onChange={(e) => setPerformanceType(e.target.value)}
                      displayEmpty
                      IconComponent={() => <ChevronDown size={20} />}
                    >
                      <MenuItem value="">Select Performance Type</MenuItem>
                      <MenuItem value="Solo Act">Solo Act</MenuItem>
                      <MenuItem value="Duo">Duo</MenuItem>
                      <MenuItem value="Band">Band</MenuItem>
                      <MenuItem value="DJ">DJ</MenuItem>
                      <MenuItem value="Ensemble">Ensemble</MenuItem>
                    </Select>
                  </Grid>
                )}
    
                <Grid item xs={12} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={handleSearch}
                    sx={{
                      bgcolor: '#EF6191',
                      '&:hover': {
                        bgcolor: '#7a3b91'
                      }
                    }}
                    startIcon={<Search size={20} />}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
    
          <Box sx={{ mb: 4 }}>
            {feedItems.length > 0 ? (
              feedItems.map((item, index) => (
                <EventCard key={index} item={item} />
              ))
            ) : (
              <Typography
                variant="body1"
                sx={{
                  textAlign: 'center',
                  color: 'text.secondary',
                  py: 6
                }}
              >
                No events found. Try adjusting your search criteria.
              </Typography>
            )}
          </Box>
    
          {showContractPopup && selectedBooking && (
            <ArtistBookingBidPopup
              booking={selectedBooking}
              onClose={() => setShowContractPopup(false)}
            />
          )}
        </Box>
      );
    };
    
    export default StyledFeed;
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import './viewProfile.css';
import ArtistBookingBidPopup from './ArtistBookingBidPopup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {Tooltip} from "@mui/material";
import {Calendar, momentLocalizer} from "react-big-calendar";
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

const localizer = momentLocalizer(moment);

const eventStyleGetter = (event, start, end, isSelected) => {
    let style = {
        backgroundColor: '#4caf50', // Default color for available dates
        borderRadius: '0px',
        opacity: 0.8,
        color: 'white',
        border: '0px',
        display: 'block'
    };

    if (event.isBooked) {
        style.backgroundColor = '#e82121'; // Red for booked dates
    }

    return { style };
};


function ViewProfile() {
    const { profile_id } = useParams();
    const [profile, setProfile] = useState();
    const [selectedData, setSelectedData] = useState(null);
    const [showContractPopup, setShowContractPopup] = useState(false);
    const [data, setData] = useState([{}]);
    const [artistName, setArtistName] = useState();
    const [showBookedArtist, setBookedArtist] = useState(false);
    const [events, setEvents] = useState([]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const renderImages = () => {
        if (profile.type === 'vendor') {
            if (profile.images && profile.images.length > 0) {
                return (
                    <div
                        className="profile-carousel"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%'
                        }}

                    >
                        <Slider {...settings}>
                            {profile.images.map((imageUrl, index) => (
                                <div key={index} style={{display: 'flex', justifyContent: 'center'}}>
                                    <img
                                        src={imageUrl}
                                        alt={`${profile.name} ${index + 1}`}
                                        style={{maxWidth: '100%', height: 'auto'}}
                                    />
                                </div>
                            ))}
                        </Slider>
                    </div>
                );
            }
        }
        return null;
    };

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(
                    `https://api.live-and-local.com/view_profile/${profile_id}`);

                const data = response.data;
                setProfile(data);
                let bookings_response;
                console.log(profile_id);
                if (data.type === 'vendor') {
                    bookings_response = (await axios.post(`
                https://api.live-and-local.com/getArtistBookingsFromViewProfile/${profile_id}`, {})).data;
                }
                else {
                    bookings_response = (await axios.post(`
                https://api.live-and-local.com/getArtistBookingsByID/${profile_id}`, {})).data;
                }
                setData(bookings_response);
                console.log(bookings_response);
                const mappedEvents = bookings_response.map(booking => {
                    const date = booking.date.split(' ').slice(0, 4).join(' ');
                    const start = moment(`${date} ${booking.start_time}`, 'ddd, DD MMM YYYY HH:mm:ss').toDate();
                    const end = moment(`${date} ${booking.end_time}`, 'ddd, DD MMM YYYY HH:mm:ss').toDate();
                    if (data.type === 'vendor') {
                        let title = "Available";
                        if (booking.status === 'booked') {
                            title = "Booked"
                        }
                        return {
                            id: booking.booking_id,
                            title: title,
                            start: start,
                            end: end,
                            isBooked: booking.status.toLowerCase() === 'booked',
                            venue: booking.venue_name || 'Main Stage',
                            description: booking.details?.description || booking.details || '',
                            capacity: parseInt(booking.capacity, 10) || 500,
                            genres: booking.genre ? booking.genre.split(', ') : [],
                            techSpecs: booking.details?.techSpecs || {}
                        };
                    }
                    else if (booking.status === 'Booked') {
                        return {
                            title: `Booked at ${booking.name}`,
                            start: start,
                            end: end,
                            isBooked: booking.status.toLowerCase() === 'booked',
                            venue: booking.venue_name || 'Main Stage',
                            description: booking.artist_notes,
                            artistName: booking.artistName,
                            location: booking.location
                        };
                    }
                });

                setEvents(mappedEvents);
                console.log(events);
                setLoading(false);
            } catch (err) {
                console.error("Failed to fetch profile data:", err);
                setError('Failed to fetch profile data');
                setLoading(false);
            }
        };

        fetchProfile();
    }, [profile_id]);


    const handleDateClick = (date) => {
        if (date.title === 'Booked') {
            axios.get(`https://api.live-and-local.com/getArtistPlaying/${date.id}`)
                .then(response => {
                    const artistName = response.data;
                    setArtistName(artistName[0].artist_name);
                    setBookedArtist(true);
                    setSelectedData(date)
                })
                .catch(error => {
                    console.error('Error fetching artist name:', error);
                });
        } else if (date.title === "Available") {
            const bookingId = date.id;
            let matchedData = data.find(item => item.booking_id === bookingId);
            matchedData.start_time = moment(date.start).format('h:mm A')
            matchedData.end_time = moment(date.end).format('h:mm A')
            matchedData.date = moment(date.end).format('MM/DD/YYYY')
            setSelectedData(matchedData);
            setShowContractPopup(true);
        }

    };

    const handleCloseContractPopup = () => {
        setShowContractPopup(false);
    };

    const ArtistNameHandleClose = () => {
        setArtistName(null);
        setBookedArtist(false);
    }

    const EventComponent = ({ event }) => (
        <Tooltip
            title={
                <div>
                    <strong>{event.title}</strong>
                    {profile.type === 'vendor' ? (
                        <>
                            <p>Venue: {event.venue || 'N/A'}</p>
                            <p>Time: {moment(event.start).format('h:mm A')} - {moment(event.end).format('h:mm A')}</p>
                            <p>Capacity: {event.capacity || 'N/A'}</p>
                            <p>Genres: {event.genres && event.genres.length > 0 ? event.genres.join(', ') : 'N/A'}</p>
                            <p>Description: {event.description || 'N/A'}</p>
                        </>
                    ) : (
                        <>
                            <p>Artist: {event.artistName || 'N/A'}</p>
                            <p>Time: {moment(event.start).format('h:mm A')} - {moment(event.end).format('h:mm A')}</p>
                            <p>Location: {event.location}</p>
                            <p>Artist Notes: {event.description}</p>
                        </>
                    )}

                </div>
            }
            arrow
        >
            <div>{event.title}</div>
        </Tooltip>

    );

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <div className="user-profile">
                <h2>{profile.name || 'Default Name'}</h2>
                <>
                    {profile.type === 'vendor' ? (
                        renderImages()
                    ) : (
                        <img
                            src={profile.images[0] || '/default-image.png'}
                            alt={profile.name}
                            style={{
                                maxWidth: '300px',
                                borderRadius: '10px',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
                            }}
                        />
                    )}
                </>
                <p style={{alignItems: 'left'}}><strong>Bio:</strong> {profile.bio || 'No bio available'}</p>
                {profile.type === 'vendor' && (
                    <>
                        <table>
                            <tbody>
                            <tr>
                                <td><p><strong>Location:</strong><br/> {profile.location || 'No location available'}</p>
                                </td>
                                <td><p><strong>Setting:</strong><br/> {profile.setting || 'No setting specified'}</p>
                                </td>
                                <td><p><strong>Stage
                                    Types: </strong><br/> {profile.stageTypes || 'No stage type specified'}</p></td>
                                <td><p><strong>Average
                                    Attendance:</strong> {profile.averageAttendance || 'No average attendance available'}
                                </p></td>
                            </tr>
                            </tbody>
                        </table>
                        <p><strong>Other Notes:</strong> {profile.amenities || 'No amamenties specified'}</p>
                    </>
                )}

                {profile.type === 'performer' && (
                    <>
                        <table>
                            <tbody>
                            <tr>
                                <td><p><strong>Location:</strong><br/> {profile.location
                                    || 'No location available'}</p></td>
                                <td><p><strong>Genre:</strong><br/> {profile.genre || 'No genre specified'}</p></td>
                                <td><p><strong>Type:</strong><br/> {profile.performance_type || 'No type available'}
                                </p></td>
                                <td><p>
                                    <strong>Experience:</strong><br/> {profile.experience_level || 'No Experience available'}
                                </p></td>
                                <td><p><strong>Preferred Venues:</strong><br/> {profile.perferred_venues
                                    || 'No preferred_venues available'}</p></td>
                            </tr>
                            </tbody>
                        </table>
                    </>
                )}
                <p>
                <a href={`https://instagram.com/${profile.instagram}`} target={'_blank'} className='icons'>
                        <InstagramIcon style={{fontSize: '2em'}}/>
                    </a>
                    <t/>
                    <a href={profile.facebook} target={'_blank' } className='icons'>
                        <FacebookIcon style={{fontSize: '2em'}}/>
                    </a>
                    <t/>
                    <a href={profile.spotify} target={'_blank'} className='icons'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                            <path fill="currentColor"
                                  d="M17.9 10.9C14.7 9 9.35 8.8 6.3 9.75c-.5.15-1-.15-1.15-.6c-.15-.5.15-1 .6-1.15c3.55-1.05 9.4-.85 13.1 1.35c.45.25.6.85.35 1.3c-.25.35-.85.5-1.3.25m-.1 2.8c-.25.35-.7.5-1.05.25c-2.7-1.65-6.8-2.15-9.95-1.15c-.4.1-.85-.1-.95-.5s.1-.85.5-.95c3.65-1.1 8.15-.55 11.25 1.35c.3.15.45.65.2 1m-1.2 2.75c-.2.3-.55.4-.85.2c-2.35-1.45-5.3-1.75-8.8-.95c-.35.1-.65-.15-.75-.45c-.1-.35.15-.65.45-.75c3.8-.85 7.1-.5 9.7 1.1c.35.15.4.55.25.85M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2"></path>
                        </svg>
                    </a>
                </p>
            </div>
            <br/>
            <div align='center'>
                <div style={{
                    height: '70vh',
                    width: '100%',
                    maxWidth: '1000px',
                    margin: '0 auto',
                }} className="responsive-calendar">
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{width: '100%', height: '100%'}}
                        selectable
                        onSelectEvent={handleDateClick}
                        eventPropGetter={eventStyleGetter}
                        components={{
                            event: EventComponent
                        }}
                        defaultDate={new Date()}
                    />
                </div>

                {showContractPopup && profile.type === 'vendor' && (
                    <ArtistBookingBidPopup
                        booking={selectedData}
                        onClose={handleCloseContractPopup}
                    />
                )}
                {showBookedArtist && profile.type === 'vendor' && (
                    <div>
                        <Dialog onClose={ArtistNameHandleClose} open={showBookedArtist}>
                            <DialogTitle>This event is already booked with {artistName}</DialogTitle>
                        </Dialog>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ViewProfile;
import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid, Card, CardContent, CardMedia } from '@mui/material';
import { AddCircle, ViewList, Info } from '@mui/icons-material';
import DatePicker from 'react-datepicker';
import axios from 'axios';

const VenueBookingLandingPage = ({ onCreateBooking, onViewBookings, venue_user_id, venue_profile_id }) => {
  const [latestBooking, setLatestBooking] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [bookedBookings, setBookedBookings] = useState([]);
  const [pendingBids, setPendingBids] = useState([]);



  useEffect(() => {
      const fetchLatestBooking = async () => {
          try {
              setIsLoading(true);
              console.log(venue_profile_id);
              const response = await axios.get(`https://api.live-and-local.com/api/latest-booking/${venue_profile_id}`);
              console.log(response)
              setLatestBooking(response.data);
              setIsLoading(false);
              
          } catch (error) {
              console.error('Error fetching latest booking:', error);
              setError('Failed to fetch the latest booking. Please try again later.');
              setIsLoading(false);
          }
      };

      fetchLatestBooking();
  }, [venue_profile_id]);


  useEffect(() => {
    const fetchDates = async () => {
        try {
            setIsLoading(true);
            console.log(venue_user_id);
            console.log("THIS IS WHERE TEH FUCK I AMSBNDBHIJUEFR#OUYKVGHFUY#")
            const response = await axios.get(`https://api.live-and-local.com/api/venue-bookings/${venue_profile_id}`);
            console.log(response)
            const fetchedBookings = response.data;
            console.log(fetchedBookings)
            setBookedBookings(fetchedBookings.filter(booking => booking.status === 'booked'));
            const convertedPendingBids = fetchedBookings
            .filter(booking => booking.status !== 'booked')
            .map(booking => {
                const date = new Date(booking.date);
                date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
                return date;
            });
            setPendingBids(convertedPendingBids);
            console.log('Pending Bids',pendingBids)
        } catch (error) {
            console.error('Error fetching latest booking:', error);
            setIsLoading(false);
        }
    };

    fetchDates();
}, [venue_user_id]);
  
  const handleDateSelect = (date) => {
      setSelectedDate(date);
      console.log('Selected date:', date);
  };

  return (
      <Box
          sx={{
              padding: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              minHeight: '80vh',
              backgroundColor: '#f5f5f5',
          }}
      >
          <Typography 
              variant="h3" 
              gutterBottom 
              sx={{ 
                  fontFamily: "'Times New Roman', Times, serif",
                  fontWeight: 'normal'
              }}
          >
              Welcome to Your Booking Dashboard
          </Typography>
          <Typography variant="h6" color="text.secondary" gutterBottom>
              Manage your venue bookings effortlessly. Post available dates, review bids from talented artists, and ensure your events are a success.
          </Typography>

          <DatePicker 
              selected={selectedDate}
              onChange={handleDateSelect}
              inline
              highlightDates={pendingBids}
              className="date-picker"
              holidays={bookedBookings}
              placeholderText="This display holidays"
          />
          <Grid container spacing={4} sx={{ mt: 4, maxWidth: '800px' }}>
              <Grid item xs={12} sm={6}>
                  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
                      <AddCircle sx={{ fontSize: 60, color: '#EF6191' }} />
                      <CardContent>
                          <Typography variant="h5" gutterBottom>
                              Create a New Booking
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                              Post available dates and details for upcoming performances. Attract top artists to bid on your events.
                          </Typography>
                          <Button 
                              variant="contained" 
                              color="primary" 
                              startIcon={<AddCircle />} 
                              sx={{ mt: 2 }}
                              onClick={onCreateBooking}
                          >
                              Create Booking
                          </Button>
                      </CardContent>
                  </Card>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
                      <ViewList sx={{ fontSize: 60, color: '#4CAF50' }} />
                      <CardContent>
                          <Typography variant="h5" gutterBottom>
                              View Existing Bookings
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                              Manage your current bookings, review artist bids, and monitor event statuses all in one place.
                          </Typography>
                          <Button 
                              variant="outlined" 
                              color="primary" 
                              startIcon={<ViewList />} 
                              sx={{ mt: 2 }}
                              onClick={onViewBookings}
                          >
                              View Bookings
                          </Button>
                      </CardContent>
                  </Card>
              </Grid>
          </Grid>
          
          <Box sx={{ mt: 6, maxWidth: '800px' }}>
              <Card sx={{ display: 'flex', alignItems: 'center', padding: 2, backgroundColor: '#ffffff' }}>
                  <CardMedia
                      component="img"
                      sx={{ width: 200, height: 150, objectFit: 'cover', borderRadius: 2 }}
                      image={latestBooking ? latestBooking.image : "/api/placeholder/200/150"}
                      alt="Latest Booking"
                  />
                  <Box sx={{ ml: 4 }}>
                      <Info sx={{ fontSize: 40, color: '#1976d2' }} />
                      <Typography variant="h5" gutterBottom>
                          Latest Booking
                      </Typography>
                      {isLoading ? (
                          <Typography variant="body2" color="text.secondary">
                              Loading latest booking information...
                          </Typography>
                      ) : error ? (
                          <Typography variant="body2" color="error">
                              {error}
                          </Typography>
                      ) : latestBooking ? (
                          <>
                              <Typography variant="body2" color="text.secondary">
                                  Date: {new Date(latestBooking.date).toLocaleDateString()}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                  Time: {latestBooking.startTime} - {latestBooking.endTime}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                  Performance Type: {latestBooking.details.performanceType}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                  Genres: {latestBooking.details.genres.join(', ')}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                  Price Range: ${latestBooking.details.priceRange.min} - ${latestBooking.details.priceRange.max}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                  Venue Type: {latestBooking.venue_type}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                  Capacity: {latestBooking.capacity}
                              </Typography>
                          </>
                      ) : (
                          <Typography variant="body2" color="text.secondary">
                              No bookings found for this venue.
                          </Typography>
                      )}
                  </Box>
              </Card>
          </Box>
      </Box>
  );
};

export default VenueBookingLandingPage;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './feed.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import HomeHeader from './HomeHeader';
import 'slick-carousel/slick/slick-theme.css';
import styled from 'styled-components';

const Feed = () => {
    const [location, setLocation] = useState(null);
    const [cityState, setCityState] = useState('');
    const [musicType, setMusicType] = useState('Rock');
    const [feedItems, setFeedItems] = useState([]);
    const genres = [
        'Rock', 'Pop', 'Jazz', 'Blues', 'Hip-Hop', 'Country', 'EDM (Electronic Dance Music)', 'Folk',
        'Reggae', 'Classical', 'Indie', 'R&B/Soul', 'Metal', 'World Music', 'Other'
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 700,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0',
        focusOnSelect: true,
        cssEase: 'cubic-bezier(0.4, 0, 0.2, 1)',
        beforeChange: (current, next) => {
            // Add classes for prev/next slides
            const slides = document.querySelectorAll('.slick-slide');
            slides.forEach(slide => {
                slide.classList.remove('slick-prev-slide', 'slick-next-slide');
            });
            const prevSlide = slides[next - 1];
            const nextSlide = slides[next + 1];
            if (prevSlide) prevSlide.classList.add('slick-prev-slide');
            if (nextSlide) nextSlide.classList.add('slick-next-slide');
        },
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    centerMode: true,
                    centerPadding: '0',
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '0',
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '0',
                    arrows: false,
                }
            }
        ]
    };

    const handleUseLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                const { latitude, longitude } = position.coords;
                setLocation({ latitude, longitude });
            }, error => {
                console.error("Error obtaining location:", error);
                alert("Unable to retrieve your location.");
            });
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };

    useEffect(() => {
        // Prompt for location on component load
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLocation({ latitude, longitude });
                },
                (error) => {
                    console.error("Error obtaining location:", error);
                    alert("Unable to retrieve your location. Showing all results.");
                    // Query the backend without location data
                    fetchFeedData(null);
                }
            );
        } else {
            alert("Geolocation is not supported by this browser. Showing all results.");
            // Query the backend without location data
            fetchFeedData(null);
        }
    }, []);

    useEffect(() => {
        if (location) {
            fetchFeedData(location);
        }
    }, [location]);

    const fetchFeedData = async (locationData) => {
        try {
            const response = await axios.post(
                'https://api.live-and-local.com/feed',
                { location: locationData },
                { withCredentials: true }
            );
            setFeedItems(response.data);
        } catch (error) {
            console.error('Error fetching feed data:', error);
            alert("There was an error fetching the feed data. Please try again.");
        }
    };

    return (
            <div className="feed-container">
                <HomeHeader />
                <br/>
                <br/>
                <h1 style={{
                    background: "linear-gradient(135deg, #EF6191 0%, #2962ff 100%)",
                    WebkitBackgroundClip: "text",
                    backgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: "700",
                    justifySelf: "center",
                    fontSize: "3rem" 
                }}>Booked Events!</h1>
                <br/>
                <br/>
                <br/>
                <div className="feed-carousel">
                    {feedItems && feedItems.length > 0 ? (
                        <Slider {...settings}>
                            {feedItems.map((item, index) => (
                                <div className="carousel-slide" key={index}>
                                    <div className="event-card">
                                        <div className="profile-picture">
                                            <img src={item.artist_url} alt={`${item.artist_name}`} />
                                        </div>
                                        <div className="artist-info">
                                            <h2>{item.artist_name}</h2>
                                            <h4>Performing at {item.venue_name}</h4>
                                        </div>
                                        <div className="event-details">
                                            <p>{item.details}</p>
                                            <p>
                                                <strong>Date:</strong> {new Date(item.date).toLocaleString()}
                                            </p>
                                            <p>
                                                <strong>Location:</strong> {item.location}
                                            </p>
                                        </div>
                                        {/* <div className="buy-tickets">
                                            <button>Buy Tickets</button>
                                        </div> */}
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <p className="no-results">No results found.</p>
                    )}
                </div>
            </div>
    );
};


export default Feed;
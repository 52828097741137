import React, { useContext, useEffect, useState } from 'react';
import { 
  Box, 
  Typography, 
  Grid,
  Paper,
  Chip,
  Button,
  Avatar,
  Rating,
  Pagination,
  IconButton,
  Fade
} from '@mui/material';
import { 
  Place,
  Event, 
  AccessTime, 
  MusicNote,
  Star,
  ThumbUp,
  TrendingUp,
  Groups,
  ArrowForward,
  ArrowBack
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../context/UserContext';
import VendorMap from './Map';
import ArtistBookingBidPopup from './ArtistBookingBidPopup';

const LocalVenues = () => {
  const [loading, setLoading] = useState(true);
  const { user } = useUser();
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const matchesPerPage = 3;
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(true);
  const [eventsError, setEventsError] = useState(null)
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [showContractPopup, setShowContractPopup] = useState(false);
  const [matchedVenuesed, setMatchedVenuesed] = useState([]);
  const indexOfLastMatch = currentPage * matchesPerPage;
  const indexOfFirstMatch = indexOfLastMatch - matchesPerPage;
  const currentMatches = matchedVenuesed.slice(indexOfFirstMatch, indexOfLastMatch);
  const totalPages = Math.ceil(matchedVenuesed.length / matchesPerPage);

  const navigate = useNavigate();

  const handleVenueClick = (venueId) => {
    navigate(`/view_profile/${venueId}`);
  };


  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const response = await axios.get(`https://api.live-and-local.com/api/artist-matches/${user.user_id}`);
        if (response.data) {
          console.log('-----------General Matches----------------------------')
          console.log(response.data);
          setMatchedVenuesed(response.data.general_matches);
          setLoading(false);
        } else {
          setError('No matches found.');
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
        setError('Failed to fetch matches.');
        setLoading(false);
      }
    };
    fetchMatches();
  }, [user]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get('https://api.live-and-local.com/api/upcoming-events');
        if (response.data) {
          setUpcomingEvents(response.data.events);
          console.log("Up COming events: ", response.data.events);
          setEventsLoading(false);
        }
      } catch (err) {
        console.error('Failed to fetch events:', err);
        setEventsError('Failed to load upcoming events');
        setEventsLoading(false);
      }
    };
  
    fetchEvents();
  }, []);

  

  const handleBidClick = (event) => {
    console.log("Event data for bid:", event);
    // Make sure event data is properly formatted before setting
    const formattedEvent = {
        ...event,
        eventType: typeof event.eventType === 'string' ? 
            JSON.parse(event.eventType) : 
            event.eventType,
        // Add any other necessary transformations
    };
    setSelectedBooking(formattedEvent);
    setShowContractPopup(true);
  };

    const handleCloseContractPopup = () => {
        setShowContractPopup(false);
    };
  const CustomPagination = ({ count, page, onChange }) => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 4,
        gap: 1  // Reduced gap from 2 to 1
      }}
    >
      <IconButton 
        onClick={() => onChange(null, Math.max(1, page - 1))}
        disabled={page === 1}
        sx={{
          color: '#924AAE',
          transition: 'all 0.2s ease',
          '&:hover': {
            bgcolor: 'transparent',
            transform: 'scale(1.1)',
            '& .MuiSvgIcon-root': {
              filter: 'drop-shadow(0 4px 12px rgba(146, 74, 174, 0.4))'
            }
          },
          '&.Mui-disabled': {
            color: 'rgba(146, 74, 174, 0.3)'
          }
        }}
      >
        <ArrowBack />
      </IconButton>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          px: 2,  // Reduced padding
          py: 0.5, // Reduced padding
          borderRadius: 1.5,
          bgcolor: 'rgba(146, 74, 174, 0.04)',
          border: '1px solid rgba(146, 74, 174, 0.1)'
        }}
      >
        <Typography
          sx={{
            color: '#924AAE',
            fontWeight: 500,
            fontSize: '0.875rem'  // Slightly smaller font
          }}
        >
          {page} of {count}
        </Typography>
      </Box>
      <IconButton 
        onClick={() => onChange(null, Math.min(count, page + 1))}
        disabled={page === count}
        sx={{
          color: '#924AAE',
          transition: 'all 0.2s ease',
          '&:hover': {
            bgcolor: 'transparent',
            transform: 'scale(1.1)',
            '& .MuiSvgIcon-root': {
              filter: 'drop-shadow(0 4px 12px rgba(146, 74, 174, 0.4))'
            }
          },
          '&.Mui-disabled': {
            color: 'rgba(146, 74, 174, 0.3)'
          }
        }}
      >
        <ArrowForward />
      </IconButton>
    </Box>
  );


  const StatCard = ({ title, value }) => (
    <Paper 
      elevation={0}
      sx={{
        p: 3,
        height: '100%',
        bgcolor: 'rgba(146, 74, 174, 0.04)',
        borderRadius: 2,
        border: '1px solid rgba(146, 74, 174, 0.1)',
        transform: 'translateY(0)',
        transition: 'all 0.2s ease',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 12px 24px -10px rgba(146, 74, 174, 0.15)',
          bgcolor: 'rgba(146, 74, 174, 0.08)',
        }
      }}
    >
      <Typography 
        variant="body1" 
        sx={{ 
          color: 'text.secondary',
          mb: 1,
          fontSize: '0.875rem'
        }}
      >
        {title}
      </Typography>
      <Typography 
        variant="h4" 
        sx={{ 
          color: '#924AAE',
          fontWeight: 500,
          letterSpacing: '-0.02em'
        }}
      >
        {value}
      </Typography>
    </Paper>
  );

  const MatchedVenueCard = ({ venue }) => (
    <Paper 
        elevation={0}
        onClick={() => handleVenueClick(venue.id)}
        sx={{
        mb: 2,
        borderRadius: 2,
        border: '1px solid rgba(0, 0, 0, 0.08)',
        overflow: 'hidden',
        transform: 'translateY(0)',
        transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
        cursor: 'pointer',
        '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 12px 24px -10px rgba(146, 74, 174, 0.2)',
            borderColor: 'rgba(146, 74, 174, 0.3)',
            bgcolor: 'rgba(146, 74, 174, 0.02)',
        },
        '&:active': {
            transform: 'translateY(-2px)', // Gives a nice "click" feel
        }
        }}
    >
      <Box sx={{ p: 3 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Avatar 
              src={venue.image_url} 
              sx={{ width: 60, height: 60 }}
            />
          </Grid>
          <Grid item xs>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography 
                variant="h6" 
                sx={{ 
                  fontWeight: 500,
                  color: '#924AAE',
                  mr: 2
                }}
              >
                {venue.name}
              </Typography>
              <Chip 
                label={`${venue.match_score}% Match`}
                size="small"
                sx={{ 
                  bgcolor: 'rgba(46, 160, 67, 0.1)',
                  color: 'rgb(46, 160, 67)',
                  fontWeight: 500
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
              <Rating value={venue.rating} precision={0.1} size="small" readOnly />
              <Typography variant="body2" color="text.secondary">
                {venue.rating} rating
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Place sx={{ color: '#924AAE', mr: 1, fontSize: 20 }} />
                  <Typography variant="body2" color="text.secondary">
                    {venue.location}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <MusicNote sx={{ color: '#924AAE', mr: 1, fontSize: 20 }} />
                  <Typography variant="body2" color="text.secondary">
                    {venue.setting}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Groups sx={{ color: '#924AAE', mr: 1, fontSize: 20 }} />
                  <Typography variant="body2" color="text.secondary">
                    {venue.capacity}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TrendingUp sx={{ color: '#924AAE', mr: 1, fontSize: 20 }} />
                  <Typography variant="body2" color="text.secondary">
                    {venue.avgPayout}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );

  const UpcomingEventCard = ({ event }) => {

    const displayEventType = React.useMemo(() => {
        try {
            if (typeof event.eventType === 'string') {
                const parsed = JSON.parse(event.eventType);
                return Array.isArray(parsed) ? parsed.join(', ') : parsed;
            }
            return Array.isArray(event.eventType) ? event.eventType.join(', ') : event.eventType;
        } catch (e) {
            return event.eventType || 'Not specified';
        }
    }, [event.eventType]);

    return(

        <Paper 
            elevation={0}
            sx={{
            mb: 2,
            borderRadius: 2,
            border: '1px solid rgba(0, 0, 0, 0.08)',
            overflow: 'hidden',
            transform: 'translateY(0)',
            transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
            cursor: 'pointer',
            '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 12px 24px -10px rgba(146, 74, 174, 0.2)',
                borderColor: 'rgba(146, 74, 174, 0.3)',
                bgcolor: 'rgba(146, 74, 174, 0.02)',
            }
            }}
        >
            <Box sx={{ p: 3 }}>
            <Box 
                sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                mb: 3
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Avatar 
                    src={event.venueProfile.image} 
                    alt={event.venueName}
                    sx={{ width: 48, height: 48 }}
                />
                <Box>
                    <Typography 
                    variant="h6" 
                    sx={{ 
                        fontWeight: 500,
                        color: '#924AAE',
                        letterSpacing: '-0.02em'
                    }}
                    >
                    {event.venueName}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Rating value={event.venueProfile.rating} precision={0.1} size="small" readOnly />
                    <Typography variant="body2" color="text.secondary">
                        {event.venueProfile.rating}
                    </Typography>
                    </Box>
                </Box>
                </Box>
                <Chip 
                label={`Deadline: ${new Date(event.date).toLocaleDateString()}`}
                size="small"
                sx={{ 
                    bgcolor: 'rgba(25, 118, 210, 0.1)',
                    color: 'rgb(25, 118, 210)',
                    fontWeight: 500
                }}
                />
            </Box>

            <Grid container spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Event sx={{ color: '#924AAE', mr: 1.5, fontSize: 20 }} />
                    <Typography variant="body2" color="text.secondary">
                    {new Date(event.date).toLocaleDateString()}
                    </Typography>
                </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AccessTime sx={{ color: '#924AAE', mr: 1.5, fontSize: 20 }} />
                    <Typography variant="body2" color="text.secondary">
                    {event.time}
                    </Typography>
                </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Place sx={{ color: '#924AAE', mr: 1.5, fontSize: 20 }} />
                    <Typography variant="body2" color="text.secondary">
                    {event.location}
                    </Typography>
                </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <MusicNote sx={{ color: '#924AAE', mr: 1.5, fontSize: 20 }} />
                    <Typography variant="body2" color="text.secondary">
                    {event.genre || event.eventType}
                    </Typography>
                </Box>
                </Grid>
            </Grid>

            {event.description && (
                <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ mb: 3 }}
                >
                {event.description}
                </Typography>
            )}

            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                alignItems: 'center',
                pt: 2,
                borderTop: '1px solid rgba(0, 0, 0, 0.06)'
            }}>
                <Typography variant="subtitle1" fontWeight={500}>
                {event.paymentRange}
                </Typography>
                <Button 
                variant="contained"
                sx={{ 
                    bgcolor: '#924AAE',
                    '&:hover': {
                    bgcolor: '#7a3b91'
                    }
                }}
                onClick={() => handleBidClick(event)}
                >
                View Contract
                </Button>
            </Box>
            </Box>
        </Paper>
        ); 
    };
    const [currentEventPage, setCurrentEventPage] = useState(1);
    const eventsPerPage = 3;

    const indexOfLastEvent = currentEventPage * eventsPerPage;
    const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
    const currentEvents = upcomingEvents.slice(indexOfFirstEvent, indexOfLastEvent);
    const totalEventPages = Math.ceil(upcomingEvents.length / eventsPerPage);

    class ErrorBoundary extends React.Component {
        constructor(props) {
            super(props);
            this.state = { hasError: false };
        }
    
        static getDerivedStateFromError(error) {
            return { hasError: true };
        }
    
        componentDidCatch(error, errorInfo) {
            console.log('Error in component:', error, errorInfo);
        }
    
        render() {
            if (this.state.hasError) {
                return (
                    <Box sx={{ p: 3, textAlign: 'center' }}>
                        <Typography color="error">
                            Something went wrong loading this content.
                        </Typography>
                        <Button 
                            onClick={() => this.setState({ hasError: false })}
                            sx={{ mt: 2 }}
                        >
                            Try Again
                        </Button>
                    </Box>
                );
            }
    
            return this.props.children;
        }
    }
    

  return (
    <Box 
      sx={{ 
        maxWidth: 1200, 
        mx: 'auto', 
        p: { xs: 2, sm: 3, md: 4 },
        bgcolor: '#ffffff'
      }}
    >
      {/* Header */}
      <Box sx={{ mb: 6, maxWidth: 720 }}>
        <Typography 
          variant="h3" 
          sx={{ 
            mb: 2, 
            fontWeight: 600,
            color: 'text.primary',
            letterSpacing: '-0.02em'
          }}
        >
          Local Venues
        </Typography>
        <Typography 
          variant="body1" 
          sx={{ 
            color: 'text.secondary',
            lineHeight: 1.6
          }}
        >
          Discover nearby venues and upcoming opportunities that match your profile
        </Typography>
      </Box>

      {/* Stats */}
      <Grid container spacing={3} sx={{ mb: 6 }}>
        <Grid item xs={12} sm={4}>
          <StatCard 
            title="Profile Matches"
            value={matchedVenuesed.length}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatCard 
            title="Upcoming Events"
            value={upcomingEvents.length}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatCard 
            title="Avg. Match Rate"
            value="93%"
          />
        </Grid>
      </Grid>

      {/* Top Profile Matches Section */}
      <Box sx={{ mb: 6 }}>
        <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 3 
        }}>
            <Typography 
            variant="h5" 
            sx={{ 
                fontWeight: 500,
                color: 'text.primary',
                letterSpacing: '-0.02em'
            }}
            >
            Top Profile Matches
            </Typography>
            <Typography 
            variant="body2" 
            sx={{ 
                color: 'text.secondary',
                fontWeight: 500
            }}
            >
            Showing {indexOfFirstMatch + 1}-{Math.min(indexOfLastMatch, matchedVenuesed.length)} of {matchedVenuesed.length}
            </Typography>
        </Box>
        
        <Box sx={{ position: 'relative', minHeight: '400px' }}>
            {currentMatches.map((venue, index) => (
            <Fade 
                key={venue.id} 
                in={true} 
                timeout={300} 
                style={{ 
                transitionDelay: `${index * 100}ms`
                }}
            >
                <Box>
                <MatchedVenueCard venue={venue} />
                </Box>
            </Fade>
            ))}
        </Box>

        {totalPages > 1 && (
            <CustomPagination
            count={totalPages}
            page={currentPage}
            onChange={(_, page) => setCurrentPage(page)}
            />
        )}
      </Box>

      {/* Upcoming Events Section */}
        <Box sx={{ mb: 6 }}>
        <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 3 
        }}>
            <Typography 
            variant="h5" 
            sx={{ 
                fontWeight: 500,
                color: 'text.primary',
                letterSpacing: '-0.02em'
            }}
            >
            Upcoming Events
            </Typography>
            <Typography 
            variant="body2" 
            sx={{ 
                color: 'text.secondary',
                fontWeight: 500
            }}
            >
            Showing {indexOfFirstEvent + 1}-{Math.min(indexOfLastEvent, upcomingEvents.length)} of {upcomingEvents.length}
            </Typography>
        </Box>
        
        <Box sx={{ position: 'relative', minHeight: '400px' }}>
            {currentEvents.map((event, index) => (
            <Fade 
                key={event.id} 
                in={true} 
                timeout={300} 
                style={{ 
                transitionDelay: `${index * 100}ms`
                }}
            >
                <Box>
                <UpcomingEventCard event={event} />
                </Box>
            </Fade>
            ))}
        </Box>

        {totalEventPages > 1 && (
            <CustomPagination
            count={totalEventPages}
            page={currentEventPage}
            onChange={(_, page) => setCurrentEventPage(page)}
            />
        )}
        </Box>

        {showContractPopup && (
            <ErrorBoundary>
                <ArtistBookingBidPopup
                    booking={selectedBooking}
                    onClose={handleCloseContractPopup}
                />
            </ErrorBoundary>
        )}
      {/* Map Section */}
      <Box sx={{ mb: 6 }}>
        <Typography 
          variant="h5" 
          sx={{ 
            mb: 3, 
            fontWeight: 500,
            color: 'text.primary',
            letterSpacing: '-0.02em'
          }}
        >
          Venues Near You
        </Typography>
        <Paper 
          elevation={0}
          sx={{
            borderRadius: 2,
            border: '1px solid rgba(0, 0, 0, 0.08)',
            overflow: 'hidden'
          }}
        >
          <VendorMap />
        </Paper>
      </Box>
    </Box>
  );
};

export default LocalVenues;
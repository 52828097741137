import React, { useState, useEffect } from 'react';
import {
    TextField, Autocomplete, Box, Button, Chip, Accordion, AccordionSummary, AccordionDetails, Typography,
    FormGroup, FormControlLabel, Checkbox, Grid, Radio, RadioGroup, CardMedia, Card, CardContent
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment-timezone';

const SearchPage = () => {
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [location, setLocation] = useState('');
    const [artistType, setArtistType] = useState({
        solo: false,
        band: false,
        duo: false,
        ensemble: false
    });
    const [venueType, setVenueType] = useState({
        Bar: false,
        Nightclub: false,
        Cafe: false,
        Restaurant: false,
        Lounge: false,
        "Concert Hall": false,
        "Outdoor Space": false,
        "Brewery/Tavern": false,
        "Private Event Space": false,
        Other: false
    });
    const [genre, setGenre] = useState({
        Rock: false,
        Pop: false,
        Jazz: false,
        Blues: false,
        "Hip-Hop": false,
        Country: false,
        "EDM (Electronic Dance Music)": false,
        Folk: false,
        Reggae: false,
        Classical: false,
        Indie: false,
        "R&B/Soul": false,
        Metal: false,
        "World Music": false,
        Other: false
    });
    const [filters, setFilters] = useState([]);
    const [bookingStatus, setBookingStatus] = useState(null);
    const [position, setPosition] = useState({"lat": null, "long": null})
    const [useCurrentLocation, setUseCurrentLocation] = useState(false);
    const [dateRange, setDateRange] = useState({ start: '', end: '' });
    const [profileType, setProfileType] = useState(null);
    const [searchString, setSearchString] = useState(null);
    const [data, setData] = useState({});
    const [page, setPage] = useState(6)

    useEffect(() => {
        const genreFilters = selectedGenres.map(genre => ({key: `Genre: ${genre}`, label: `Genre: ${genre}`}));
        const locationFilter = location ? [{key: `Location: ${location}`, label: `Location: ${location}`}] : [];
        const artistTypeFilters = Object.entries(artistType).filter(([key, value]) => value).map(([key]) => ({
            key: `Artist Type: ${key}`,
            label: `Artist Type: ${key.charAt(0).toUpperCase() + key.slice(1)}`
        }));
        const venueTypeFilters = Object.entries(venueType).filter(([key, value]) => value).map(([key]) => ({
            key: `Venue Type: ${key}`,
            label: `Venue Type: ${key.charAt(0).toUpperCase() + key.slice(1)}`
        }));
        const genreFiltersAdded = Object.entries(genre).filter(([key, value]) => value).map(([key]) => ({
            key: `Genre: ${key}`,
            label: `Genre: ${key.charAt(0).toUpperCase() + key.slice(1)}`
        }));
        const bookingStatusFilter = bookingStatus ? [{
            key: `Booking Status: ${bookingStatus}`,
            label: `Booking Status: ${bookingStatus.charAt(0).toUpperCase() + bookingStatus.slice(1)}`
        }] : [];
        const dateRangeFilter = dateRange.start && dateRange.end ? [{
            key: `Date Range: ${dateRange.start} - ${dateRange.end}`,
            label: `Date Range: ${dateRange.start} - ${dateRange.end}`
        }] : [];
        const profileTypeFilter = profileType ? [{
            key: `Profile Type: ${profileType}`,
            label: `Profile Type: ${profileType.charAt(0).toUpperCase() + profileType.slice(1)}`
        }] : [];
        setFilters([...genreFilters, ...locationFilter, ...artistTypeFilters, ...venueTypeFilters,
            ...genreFiltersAdded, ...bookingStatusFilter, ...dateRangeFilter, ...profileTypeFilter]);
    }, [selectedGenres, location, artistType, venueType, genre, bookingStatus, dateRange, profileType]);

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        if (genre[name] !== undefined) {
            setGenre(prev => ({ ...prev, [name]: checked }));
        } else if (artistType[name] !== undefined) {
            setArtistType(prev => ({ ...prev, [name]: checked }));
        } else if (venueType[name] !== undefined) {
            setVenueType(prev => ({ ...prev, [name]: checked }));
        }
    };

    const buildFilterPayload = (currentPage) => {
        return {
            location: location || null,
            position: position || null,
            useCurrentLocation: useCurrentLocation,
            artistType: Object.entries(artistType)
                .filter(([_, value]) => value)
                .map(([key]) => key),
            venueType: Object.entries(venueType)
                .filter(([_, value]) => value)
                .map(([key]) => key),
            genre: Object.entries(genre)
                .filter(([_, value]) => value)
                .map(([key]) => key),
            bookingStatus: bookingStatus || null,
            dateRange: dateRange.start && dateRange.end ? dateRange : null,
            profileType: profileType || null,
            searchString: searchString || null,
            pageNumber: currentPage || 6,
        };
    };

    const fetchFilteredData = async (currentPage) => {
        setData({});
        const filterPayload = buildFilterPayload(currentPage);
        console.log(filterPayload);

        try {
            const response = await fetch('https://api.live-and-local.com/search', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(filterPayload),
            });

            const data = await response.json();
            console.log(data);
            setData(data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const genresList = [
        'Rock',
        'Pop',
        'Jazz',
        'Blues',
        'Hip-Hop',
        'Country',
        'EDM (Electronic Dance Music)',
        'Folk',
        'Reggae',
        'Classical',
        'Indie',
        'R&B/Soul',
        'Metal',
        'World Music',
        'Other'
    ];

    const venueTypesList = [
        'Bar',
        'Nightclub',
        'Café',
        'Restaurant',
        'Lounge',
        'Concert Hall',
        'Outdoor Space',
        'Brewery/Tavern',
        'Private Event Space',
        'Other'
    ];


    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    };

    const removeFilter = (filterToRemove) => {
        if (filterToRemove.startsWith('Genre:')) {
            const genre = filterToRemove.replace('Genre: ', '');
            setSelectedGenres(selectedGenres.filter(genreName => genreName !== genre));
            setGenre(prev => ({ ...prev, [genre]: false }));
        } else if (filterToRemove.startsWith('Location:')) {
            setLocation('');
            setPosition({"lat": null, "long": null});
        } else if (filterToRemove.startsWith('Artist Type:')) {
            const type = filterToRemove.replace('Artist Type: ', '').toLowerCase();
            setArtistType(prev => ({ ...prev, [type]: false }));
        } else if (filterToRemove.startsWith('Venue Type:')) {
            const type = filterToRemove.replace('Venue Type: ', '');
            setVenueType(prev => ({ ...prev, [type]: false }));
        } else if (filterToRemove.startsWith('Booking Status:')) {
            setBookingStatus(null);
        } else if (filterToRemove.startsWith('Date Range:')) {
            setDateRange({ start: '', end: '' });
        } else if (filterToRemove.startsWith('Profile Type:')) {
            setProfileType(null);
        }
    };


    const handleGetLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition, showError, {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0
            });
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };

    const showPosition = (position) => {
        setPosition({
            "lat": position.coords.latitude,
            "long": position.coords.longitude
        });
        setUseCurrentLocation(true);
        setLocation("Current Location");
    };

    const showError = (error) => {
        switch(error.code) {
            case error.PERMISSION_DENIED:
                alert("User denied the request for Geolocation.");
                break;
            case error.POSITION_UNAVAILABLE:
                alert("Location information is unavailable.");
                break;
            case error.TIMEOUT:
                alert("The request to get user location timed out.");
                break;
            case error.UNKNOWN_ERROR:
                alert("An unknown error occurred.");
                break;
        }
    };

    return (
        <div>
        <Box sx={{ width: '100%', padding: '20px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: '20px' }}>
                <TextField
                    fullWidth
                    label="Enter a name or apply filters to search..."
                    onChange={(e) => setSearchString(e.target.value)}
                    sx={{ flex: 3 }}
                />
                <Button
                    variant="contained"
                    onClick={() => {
                        setPage(6);
                        fetchFilteredData();
                    }}
                    style={{ backgroundColor: '#EF6191', flex: 1}}
                >
                    Search
                </Button>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', marginBottom: '20px' }}>
                {filters.map(filter => (
                    <Chip
                        key={filter.key}
                        label={filter.label}
                        onDelete={() => removeFilter(filter.key)}
                        sx={{ margin: '5px', backgroundColor: '#f06292', color: 'white', borderRadius: '16px' }}
                    />
                ))}
            </Box>
        </Box>
        <Box sx={{ width: '100%', padding: '20px' }}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Accordion defaultExpanded sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">Filters</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ flex: 1, overflow: 'auto' }}>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Profile Type</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <RadioGroup
                                        value={profileType}
                                        onChange={(e) => setProfileType(e.target.value)}
                                    >
                                        <FormControlLabel value="Artist" control={<Radio />} label="Artist" />
                                        <FormControlLabel value="Venue" control={<Radio />} label="Venue" />
                                    </RadioGroup>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Location</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TextField
                                        fullWidth
                                        label="City, State ex: (Boston, MA)"
                                        value={location}
                                        onChange={handleLocationChange}
                                    />
                                    <Button variant="contained"
                                            onClick={handleGetLocation}
                                            style={{backgroundColor: '#EF6191'}}
                                    >
                                        Use Current Location
                                    </Button>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Artist Type</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={artistType.solo} onChange={handleCheckboxChange} name="solo" />} label="Solo" />
                                        <FormControlLabel control={<Checkbox checked={artistType.band} onChange={handleCheckboxChange} name="band" />} label="Band" />
                                        <FormControlLabel control={<Checkbox checked={artistType.duo} onChange={handleCheckboxChange} name="duo" />} label="Duo" />
                                        <FormControlLabel control={<Checkbox checked={artistType.ensemble} onChange={handleCheckboxChange} name="ensemble" />} label="Ensemble" />
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Venue Type</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormGroup>
                                        {venueTypesList.map((venueName) => (
                                            <FormControlLabel
                                                key={venueName}
                                                control={
                                                    <Checkbox
                                                        checked={venueType[venueName]}
                                                        onChange={(e) =>
                                                            setVenueType((prev) => ({
                                                                ...prev,
                                                                [venueName]: e.target.checked
                                                            }))
                                                        }
                                                        name={venueName}
                                                    />
                                                }
                                                label={venueName}
                                            />
                                        ))}
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Genre</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormGroup>
                                        {genresList.map((genreName) => (
                                            <FormControlLabel
                                                key={genreName}
                                                control={
                                                    <Checkbox
                                                        checked={genre[genreName]}
                                                        onChange={(e) =>
                                                            setGenre((prev) => ({
                                                                ...prev,
                                                                [genreName]: e.target.checked
                                                            }))
                                                        }
                                                        name={genreName}
                                                    />
                                                }
                                                label={genreName}
                                            />
                                        ))}
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Booking Status</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <RadioGroup
                                        value={bookingStatus}
                                        onChange={(e) => setBookingStatus(e.target.value)}
                                    >
                                        <FormControlLabel value="booked" control={<Radio />} label="Booked" />
                                        <FormControlLabel value="available" control={<Radio />} label="Available" />
                                    </RadioGroup>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Date Range</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                        <TextField
                                            type="date"
                                            label="Start Date"
                                            InputLabelProps={{ shrink: true }}
                                            value={dateRange.start}
                                            onChange={(e) => setDateRange(prev => ({ ...prev, start: e.target.value }))}
                                        />
                                        <TextField
                                            type="date"
                                            label="End Date"
                                            InputLabelProps={{ shrink: true }}
                                            value={dateRange.end}
                                            onChange={(e) => setDateRange(prev => ({ ...prev, end: e.target.value }))}
                                        />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={9}>
                    <Box sx={{ flex: 1, marginTop: '20px', backgroundColor: '#f5f5f5', padding: '10px' }}>
                        {!data || Object.keys(data).length === 0 ? (
                            <p>Click Search & your results will appear here...</p>
                        ) : data[0] === "Profiles" && data.length > 1 ? (
                            <Grid container spacing={2}>
                                {data.slice(1).map((profile) => (
                                    <Grid item xs={6} sm={6} md={4} key={profile.profile_id}>
                                        <a href={`/view_profile/${profile.profile_id}`} style={{ textDecoration: 'none' }}>
                                            <Card
                                                key={profile.profile_id}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    width: '100%',
                                                    maxWidth: '500px',
                                                    boxShadow: 3,
                                                }}
                                            >
                                                <CardMedia
                                                    component="img"
                                                    sx={{ width: 150, height: 150, objectFit: 'cover' }}
                                                    image={profile.image}
                                                    alt={profile.name}
                                                />
                                                <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Typography gutterBottom variant="h6" component="div">
                                                        {profile.name}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        <strong>Genre:</strong> {profile.genre}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        <strong>Location:</strong> {profile.location || "Location not available"}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        <strong>Type:</strong> {profile.type}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </a>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : data[0] === "Events" && data.length > 1 ? (
                            <Grid container spacing={2}>
                                {data.slice(1).map((event) => (
                                    <Grid item xs={6} key={event.available_date_id}>
                                        <Card
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: '100%',
                                                boxShadow: 3,
                                                padding: 2,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 2,
                                                }}
                                            >
                                                <img
                                                    src={event.venue_pic}
                                                    alt={event.venue_name}
                                                    style={{ width: 100, height: 100, objectFit: 'cover', borderRadius: 8 }}
                                                />

                                                {event.status === "Booked" && event.artist_pic ? (
                                                    <img
                                                        src={event.artist_pic}
                                                        alt={event.artist_name}
                                                        style={{ width: 100, height: 100, objectFit: 'cover', borderRadius: 8 }}
                                                    />
                                                ) : event.status !== "Booked" ? (
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            width: 100,
                                                            height: 100,
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            borderRadius: 2,
                                                            backgroundColor: '#f06292'
                                                        }}
                                                        onClick={() => console.log("Button clicked")}
                                                    >
                                                        Bid Now
                                                    </Button>
                                                ) : null}
                                            </Box>
                                            <CardContent>
                                                <Typography gutterBottom variant="h6" component="div">
                                                    {event.venue_name}
                                                    {event.status === "Booked" && (` hosts ${event.artist_name}`)}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    <strong>Date:</strong> {moment.utc(event.date).tz('UTC').format('MM/DD/YYYY')} |
                                                    <strong> Time:</strong> {event.start_time} - {event.end_time}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    <strong>Location:</strong> {event.location}
                                                </Typography>
                                                {event.status === "Booked" && (
                                                <Typography variant="body2" color="text.secondary">
                                                    <strong>Genre:</strong> {event.genre}
                                                </Typography>
                                                )}
                                                {event.status !== "Booked" && (
                                                    <Typography variant="body2" color="text.secondary">
                                                        <strong>Setting:</strong> {event.setting}
                                                    </Typography>
                                                )}
                                                <Typography variant="body2" color="text.secondary">
                                                    <strong>Status:</strong> {event.status.replace("_", " ")}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : data.length === 1 && page >= 6 ? (
                            <p>No Results found</p>
                        ) : null}
                    </Box>
                    { (data.length >= 1 && page >= 6) && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, marginTop: 2 }}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setPage((prevPage) => {
                                        const newPage = prevPage - 6;
                                        fetchFilteredData(newPage);
                                        return newPage;
                                    });
                                }}
                                disabled={page === 6}
                                sx={{backgroundColor: '#f06292'}}
                            >
                                Previous
                            </Button>
                            <Typography variant="body2" color="text.secondary" sx={{ alignSelf: 'center' }}>
                                Results {page-6} - {page}
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setPage((prevPage) => {
                                        const newPage = prevPage + 6;
                                        fetchFilteredData(newPage);
                                        return newPage;
                                    });
                                }}
                                sx={{backgroundColor: '#f06292'}}
                            >
                                Next
                            </Button>
                        </Box>
                    )}
            </Grid>
        </Grid>
        </Box>
        </div>
    );
};

export default SearchPage;

import React, {useEffect, useState} from 'react';
import { Box, Typography, Button, Grid, Card, CardContent, CardMedia } from '@mui/material';
import { AddCircle, ViewList, Info } from '@mui/icons-material';
import BookingImage from './87730da1-059c-4dd5-aad5-e2bbdfc061d4.webp';// Add a placeholder image in your assets
import DatePicker from 'react-datepicker';
import axios from "axios";
//import './ArtistBookingLandingPage.css';

const ArtistBookingLandingPage = ({ onFindEvent, onViewBookings }) => {
    const [pendingBids, setPendingBid] = useState([]);
    const [bookedBookings, setBookedBookings] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedData, setSelectedData] = useState(null);

    const parseDate = (dateString) => {
      if (!dateString) return null;
      
      try {
          const date = new Date(dateString);
          if (isNaN(date.getTime())) {
              console.error('Invalid date:', dateString);
              return null;
          }
          return date;
      } catch (error) {
          console.error('Error parsing date:', dateString, error);
          return null;
      }
  };

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const response = await axios.post('https://api.live-and-local.com/getArtistBookings', {}, { withCredentials: true });
                const fetchedBookings = response.data;
                setPendingBid(fetchedBookings.filter(booking => booking.status === 'Pending'));
                setBookedBookings(fetchedBookings.filter(booking => booking.status === 'Booked'));
                console.log('Pending bids: ', pendingBids);
                console.log('ewew bids: ', bookedBookings);
            } catch (error) {
                console.error('Error fetching bookings data:', error);
            }
        };
        fetchBookings();
    }, []);
    const bookedDates = bookedBookings
        .map(booking => ({
            date: booking.parsedDate,
            isSigned: booking.artist_signed
        }))
        .filter(booking => booking.date !== null);

    const pendingDates = pendingBids
        .map(bid => bid.parsedDate)
        .filter(date => date !== null);

    const handleDateClick = (date) => {
        const booked = bookedBookings.filter(booking => new Date(booking.date).toDateString() === date.toDateString());
        const pending = pendingBids.filter(bid => new Date(bid.date).toDateString() === date.toDateString());
        const combinedData = [...booked, ...pending];
        if (combinedData.length > 0) {
            setSelectedData(combinedData);
        } else {
            setSelectedData(null);
        }
        setSelectedDate(date);
    };
  return (
    <Box
      sx={{
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: '80vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Typography variant="h3" gutterBottom>
        Welcome to Your Booking Dashboard
      </Typography>
      <Typography variant="h6" color="text.secondary" gutterBottom>
        Manage your venue bookings effortlessly. Discover available dates, place bids, and manager your upcoming bookings.
      </Typography>
        <div>
            <DatePicker
                selected={selectedDate}
                onChange={handleDateClick}
                inline
                highlightDates={pendingDates}
                className="date-picker"
                holidays={bookedDates}
            />
            {selectedData && selectedData.length > 0 && (
                <div className="booking-details">
                    <h3>Date Details:</h3>
                    <hr/>
                    {selectedData.map((booking, index) => (
                        <div key={index} className="booking-item">
                            {booking.name && <p><strong>Venue:</strong> {booking.name}</p>}
                            {booking.status && <p><strong>Status:</strong> {booking.status}</p>}
                            {booking.status === "Booked" && (
                                <p><strong>Signed Contract:</strong> {booking.artist_signed === 1 ? "Yes" : "No"}</p>
                            )}
                            <hr/>
                        </div>
                    ))}
                </div>
            )}
        </div>
      <Grid container spacing={4} sx={{ mt: 4, maxWidth: '800px' }}>
        <Grid item xs={12} sm={6}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
            <AddCircle sx={{ fontSize: 60, color: '#EF6191' }} />
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Find New Events
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Discover opportunities for you to preform your next event!
              </Typography>
              <Button 
                variant="contained" 
                color="primary" 
                startIcon={<AddCircle />} 
                sx={{ mt: 2 }}
                onClick={onFindEvent}
              >
                Find Events
              </Button>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
            <ViewList sx={{ fontSize: 60, color: '#4CAF50' }} />
            <CardContent>
              <Typography variant="h5" gutterBottom>
                View Bookings
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Manage your current bookings, and monitor your bid statuses all in one place.
              </Typography>
              <Button 
                variant="outlined" 
                color="primary" 
                startIcon={<ViewList />} 
                sx={{ mt: 2 }}
                onClick={onViewBookings}
              >
                View Bookings
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      <Box sx={{ mt: 6, maxWidth: '800px' }}>
        <Card sx={{ display: 'flex', alignItems: 'center', padding: 2, backgroundColor: '#ffffff' }}>
          <CardMedia
            component="img"
            sx={{ width: 200, height: 150, objectFit: 'cover', borderRadius: 2 }}
            image={BookingImage}
            alt="Venue Booking"
          />
          <Box sx={{ ml: 4 }}>
            <Info sx={{ fontSize: 40, color: '#1976d2' }} />
            <Typography variant="h5" gutterBottom>
              Why Choose Our Booking System?
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Our platform can easily find and secure performance opportunities tailored to your genre and location preferences. It streamlines the booking process by connecting you directly with venues, allowing you to bid on gigs, manage bookings, and grow your career through exposure to new audiences.
            </Typography>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};
export default ArtistBookingLandingPage;